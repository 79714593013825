import { v4 as uuidv4 } from "uuid";

export const questionsData = [
  {
    id: 1,
    point: 0,
    type: "canvas",

  },
  {
    id: 2,
    point: 0,
    question: `2) Aşağıda, dört aşamadan oluşan bir dizi işlem bulunmaktadır. Her
        aşamada (?) yerine dört işlem sembollerinden birini koyarak sonraki
        adıma geçin ve son işlemin sonucu 11 olacak şekilde zinciri
        tamamlayın.`,
    problem: [
      { id: uuidv4(), type: "number", value: "5" },
      { id: uuidv4(), type: "drop", value: "", rightValue: ["X"] },
      { id: uuidv4(), type: "number", value: "6" },
      { id: uuidv4(), type: "equal", value: "" },
      { id: uuidv4(), type: "result", value: "" },
      { id: uuidv4(), type: "drop", value: "", rightValue: ["-"] },
      { id: uuidv4(), type: "number", value: "3" },
      { id: uuidv4(), type: "equal", value: "" },
      { id: uuidv4(), type: "result", value: "" },
      { id: uuidv4(), type: "drop", value: "", rightValue: ["/"] },
      { id: uuidv4(), type: "number", value: "3" },
      { id: uuidv4(), type: "equal", value: "" },
      { id: uuidv4(), type: "result", value: "" },
      { id: uuidv4(), type: "drop", value: "", rightValue: ["+"] },
      { id: uuidv4(), type: "number", value: "2" },
      { id: uuidv4(), type: "equal", value: "" },
      { id: uuidv4(), type: "final", value: "11" },
    ],
    type: "drag",
  },
  {
    id: 3,
    point: 0,
    question: `Aşağıdaki grafik, son 20 yıl içinde X
    bölgesindeki ortalama yıllık sıcaklık değişimini göstermektedir.
    Grafiğe göre, X bölgesindeki ortalama sıcaklık son 20 yılda nasıl bir
    değişim göstermiştir?`,
    image:"question-three-graph.png",
    answers: [
      {
        id: 1,
        answer: `İlk on yılda düzenli olarak artmış, sonraki on yılda sabit kalmış.`,
        point:2
      },
      {
        id: 2,
        answer: `İlk beş yılda hafif bir düşüş göstermiş, ardından sürekli ve düzenli bir şekilde artmış.`,
        point:4
      },
      {
        id: 3,
        answer: `Genel olarak dalgalı bir seyir izlemiş ancak ortalama sıcaklıkta önemli bir artış veya azalış gözlenmemiş.`,
        point:6
      },
      {
        id: 4,
        answer: `Son beş yıla kadar sürekli düşmüş ancak son beş yılda hızlı bir artış yaşanmış.`,
        point:8
      },
      {
        id: 5,
        answer: `İlk yarısında yavaş bir artış gösterirken ikinci yarısında hızlı bir artışa geçmiş.`,
        point:10
      },
      {
        id: 6,
        answer: `Önceleri dalgalı bir seyir izlerken, son on yılda düzenli olarak azalmış.`,
        point:10
      },
    ],
    type: "choice",
  },
  {
    id: 4,
    point: 0,
    question: `Zeynep, okulda zor bir gün geçirmiştir. Öğretmeni tarafından sınıfta eleştirilmiş ve arkadaşlarıyla bir anlaşmazlık yaşamıştır. Okuldan döndüğünde üzgün ve yorgun görünmektedir. Bu durumda Zeynep'e ne dersiniz?`,
    rightAnswer:0,
    answers: [
      {
        id: 1,
        answer: `Zeynep, neden üzgün olduğunu anlayabiliyorum. Yardıma ihtiyacın var mı?`,
        point:10
      },
      {
        id: 2,
        answer: `Zeynep, üzgün olduğunu görüyorum. Yardıma ihtiyacın var mı?`,
        point:8
      },
      {
        id: 3,
        answer: `Zeynep, kötü bir gün geçirdiğin belli. Her şey daha iyi olacak.`,
        point:6
      },
      {
        id: 4,
        answer: `Zeynep, herkes kötü günler geçirir, bu normal.`,
        point:4
      },
      {
        id: 5,
        answer: `Zeynep, belki de öğretmenin haklıydı. Durumu iyice düşünmelisin.`,
        point:2
      },
      {
        id: 6,
        answer: `Zeynep, bu tür şeylerle başa çıkmayı öğrenmelisin.`,
        point:0
      },
    ],
    type: "choice",
  },
  {
    id: 5,
    point: 0,
    question: `Bir oyuncak üreticisi olarak piyasayı ve ürününüzün fiyatlarını analiz ediyorsunuz. 
    Fark ettiniz ki oyuncağın popülaritesi arttıkça fiyatı da artıyor.
     Aynı oyuncağın popülaritesi düştükçe fiyatı da düşüyor. 
     Bu fiyat değişimini en iyi açıklayacak grafik aşağıdakilerden hangisidir?`,
    rightAnswer:1,
    answers: [
      {
        id: 1,
        answer: `answer-one.png`,
      },
      {
        id: 2,
        answer: `answer-two.png`,
      },
      {
        id: 3,
        answer: `answer-three.png`,
      },
      {
        id: 4,
        answer: `answer-four.png`,
      },
      {
        id: 5,
        answer: `answer-five.png`,
      },
    ],
    type: "choice",
  },
  {
    id: 7,
    point: 0,
    type: "dragdrop",
    drops: [
      {
        id: uuidv4(),
        value: "",
      },
      {
        id: uuidv4(),
        value: "",
      },
      {
        id: uuidv4(),
        value: "",
      },
      {
        id: uuidv4(),
        value: "",
      },
      {
        id: uuidv4(),
        value: "",
      },
      {
        id: uuidv4(),
        value: "",
      },
      {
        id: uuidv4(),
        value: "",
      },
    ],
    drags: [
      {
        id: uuidv4(),
        direction: "right",
      },
      {
        id: uuidv4(),
        direction: "left",
      },
      {
        id: uuidv4(),
        direction: "right",
      },
      {
        id: uuidv4(),
        direction: "left",
      },
      {
        id: uuidv4(),
        direction: "right",
      },
      {
        id: uuidv4(),
        direction: "left",
      },
      {
        id: uuidv4(),
        direction: "right",
      },
    ],
  },
  {
    id: 8,
    question: `Okulunuzun bilim fuarı için bir proje hazırlıyorsunuz. Projeniz,
    bir model roket yapımını içeriyor. Roketinizi tasarlarken ve inşa
    ederken çeşitli risklerle karşılaşabilirsiniz. 
    Bu riskleri nasıl yöneteceğinizi ve olası sonuçları nasıl ele alacağınızı düşündükten sonra eşleştirin.`,
    point: 0,
    values: {
      keys: [
        {
          id: 1,
          name: "Risk Tanımlama",
          value: 1,
          order: 2,
          type: 0,
        },
        {
          id: 2,
          name: "Risk Değerlendirme",
          value: 2,
          order: 3,
          type: 0,
        },
        {
          id: 3,
          name: "Önleme Stratejileri",
          value: 3,
          order: 1,
          type: 0,
        },
        {
          id: 4,
          name: "Acil Durum Planı",
          value: 4,
          order: 5,
          type: 0,
        },
        {
          id: 5,
          name: "Karar Verme",
          value: 5,
          order: 4,
          type: 0,
        },
      ],
      values: [
        {
          id: 1,
          name: "Projenizde karşılaşabileceğiniz en büyük üç riski belirlemek ve açıklamak",
          value: 1,
          order: 3,
          type: 1,
        },
        {
          id: 2,
          name: "Her bir riskin gerçekleşme olasılığını ve etkisini değerlendirmek (yüksek, orta, düşük)",
          value: 2,
          order: 1,
          type: 1,
        },
        {
          id: 3,
          name: "Her bir risk için alabileceğimiz önleyici tedbirleri yazmak",
          value: 3,
          order: 2,
          type: 1,
        },
        {
          id: 4,
          name: "Eğer riskler gerçekleşirse nasıl bir acil durum planı uygulayacağımızı belirlemek",
          value: 4,
          order: 5,
          type: 1,
        },
        {
          id: 5,
          name: "Riskler göz önüne alındığında projemizi nasıl güvenli ve etkili bir şekilde tamamlayabileceğimizi açıklamak",
          value: 5,
          order: 4,
          type: 1,
        },
      ],
    },
  },
  {
    id: 9,
    question: `Aşağıdaki öğeleri kullanarak 8 Haneli güvenli bir şifre oluşturun?`,
    point: 0,
    data: {
      alphabet: [
        {
          id: 1,
          name: "A",
          icon: `<svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.0602 28.1996L36.6602 22.3196H22.6202L20.2202 28.1996H15.6602L26.5802 0.599609H32.4202L43.3402 28.1996H39.0602ZM24.5402 17.5996H34.7402L30.0202 6.11961H29.2602L24.5402 17.5996Z" fill="currentColor"/>
          <line y1="36.6992" x2="59" y2="36.6992" stroke="currentColor" strokeWidth="3"/>
          </svg>
          
          `,
          bigger: true,
          type: "alphabet",
        },
        {
          id: 2,
          name: "B",
          icon: `<svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M35.9583 13.6396C37.3983 14.1729 38.5583 15.0929 39.4383 16.3996C40.3183 17.6796 40.7583 19.1196 40.7583 20.7196C40.7583 22.8796 40.0116 24.6663 38.5183 26.0796C37.0249 27.4929 35.1583 28.1996 32.9183 28.1996H18.2383V0.599609H32.3983C34.4783 0.599609 36.2116 1.26628 37.5983 2.59961C39.0116 3.90628 39.7183 5.55961 39.7183 7.55961C39.7183 8.86628 39.3716 10.0796 38.6783 11.1996C38.0116 12.2929 37.1049 13.1063 35.9583 13.6396ZM31.5183 5.31961H22.2783V11.9196H31.5183C32.6916 11.9196 33.6516 11.6129 34.3983 10.9996C35.1716 10.3596 35.5583 9.54628 35.5583 8.55961C35.5583 7.57294 35.1849 6.78628 34.4383 6.19961C33.6916 5.61294 32.7183 5.31961 31.5183 5.31961ZM32.0383 23.4796C33.3983 23.4796 34.4916 23.1463 35.3183 22.4796C36.1716 21.7863 36.5983 20.8929 36.5983 19.7996C36.5983 18.6529 36.1716 17.7329 35.3183 17.0396C34.4916 16.3463 33.3983 15.9996 32.0383 15.9996H22.2783V23.4796H32.0383Z" fill="currentColor"/>
          <line y1="36.6992" x2="59" y2="36.6992" stroke="currentColor" strokeWidth="3"/>
          </svg>
          
          `,
          bigger: true,
          type: "alphabet",
        },
        {
          id: 3,
          name: "C",
          icon: `<svg width="59" height="40" viewBox="0 0 59 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.7388 29.2006C25.6054 29.2006 22.1521 27.8406 19.3788 25.1206C16.6054 22.374 15.2188 18.974 15.2188 14.9206C15.2188 10.8673 16.6054 7.48063 19.3788 4.76062C22.1521 2.01396 25.6054 0.640625 29.7388 0.640625C33.2321 0.640625 36.2588 1.64062 38.8188 3.64062C41.4054 5.64062 43.0588 8.22729 43.7788 11.4006H39.4588C38.7388 9.61396 37.4988 8.18729 35.7388 7.12063C34.0054 6.02729 32.0054 5.48062 29.7388 5.48062C26.8054 5.48062 24.3521 6.38729 22.3788 8.20063C20.4054 9.98729 19.4188 12.2273 19.4188 14.9206C19.4188 17.614 20.4054 19.8673 22.3788 21.6806C24.3521 23.4673 26.8054 24.3606 29.7388 24.3606C32.0054 24.3606 34.0054 23.8273 35.7388 22.7606C37.4988 21.6673 38.7388 20.2273 39.4588 18.4406H43.7788C43.0588 21.614 41.4054 24.2006 38.8188 26.2006C36.2588 28.2006 33.2321 29.2006 29.7388 29.2006Z" fill="currentColor"/>
          <line y1="37.7012" x2="59" y2="37.7012" stroke="currentColor" strokeWidth="3"/>
          </svg>
                   
          `,
        },
        {
          id: 4,
          name: "d",
          icon: `<svg width="59" height="41" viewBox="0 0 59 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M36.5802 0.919922H40.3402V29.7199H36.5802V25.3999C35.7802 26.8933 34.6868 28.0666 33.3002 28.9199C31.9402 29.7733 30.4068 30.1999 28.7002 30.1999C25.8202 30.1999 23.4202 29.1866 21.5002 27.1599C19.6068 25.1066 18.6602 22.5599 18.6602 19.5199C18.6602 16.4799 19.6068 13.9466 21.5002 11.9199C23.4202 9.86659 25.8202 8.83992 28.7002 8.83992C30.4068 8.83992 31.9402 9.26659 33.3002 10.1199C34.6868 10.9733 35.7802 12.1466 36.5802 13.6399V0.919922ZM29.5802 25.6399C31.5802 25.6399 33.2468 25.0533 34.5802 23.8799C35.9135 22.7066 36.5802 21.2533 36.5802 19.5199C36.5802 17.7866 35.9135 16.3333 34.5802 15.1599C33.2468 13.9866 31.5802 13.3999 29.5802 13.3999C27.5535 13.3999 25.8735 13.9866 24.5402 15.1599C23.2068 16.3333 22.5402 17.7866 22.5402 19.5199C22.5402 21.2533 23.2068 22.7066 24.5402 23.8799C25.8735 25.0533 27.5535 25.6399 29.5802 25.6399Z" fill="currentColor"/>
          <line y1="38.6992" x2="59" y2="38.6992" stroke="currentColor" strokeWidth="3"/>
          </svg>
                          
          `,
          bigger: false,
          type: "alphabet",
        },
        {
          id: 5,
          name: "e",
          icon: `<svg width="59" height="33" viewBox="0 0 59 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M40.4181 11.5998C40.4181 12.0265 40.3915 12.5332 40.3381 13.1198H22.4581C22.8315 14.5065 23.6715 15.6265 24.9781 16.4798C26.2848 17.3065 27.8448 17.7198 29.6581 17.7198C32.3515 17.7198 34.4048 17.0398 35.8181 15.6798H40.0981C39.2715 17.6532 37.9115 19.2398 36.0181 20.4398C34.1248 21.6132 31.9648 22.1998 29.5381 22.1998C26.3915 22.1998 23.7781 21.1865 21.6981 19.1598C19.6181 17.1065 18.5781 14.5598 18.5781 11.5198C18.5781 8.47984 19.6181 5.94651 21.6981 3.91984C23.7781 1.86651 26.3781 0.839844 29.4981 0.839844C32.6181 0.839844 35.2181 1.86651 37.2981 3.91984C39.3781 5.94651 40.4181 8.50651 40.4181 11.5998ZM34.0581 6.59984C32.7515 5.74651 31.2315 5.31984 29.4981 5.31984C27.7648 5.31984 26.2581 5.74651 24.9781 6.59984C23.6981 7.42651 22.8715 8.51984 22.4981 9.87984H36.5781C36.2048 8.51984 35.3648 7.42651 34.0581 6.59984Z" fill="currentColor"/>
          <line y1="30.6992" x2="59" y2="30.6992" stroke="currentColor" strokeWidth="3"/>
          </svg>
                                    
          `,
          bigger: false,
        },
        {
          id: 6,
          name: "f",
          icon: `<svg width="59" height="40" viewBox="0 0 59 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M33.4406 4.55914C31.6806 4.55914 30.8006 5.39914 30.8006 7.07914V8.79914H36.3606V13.2391H30.8006V29.1991H27.0006V13.2391H22.6406V8.79914H27.0006V5.99914C27.0006 2.07914 29.0406 0.119141 33.1206 0.119141H36.3606V4.55914H33.4406Z" fill="currentColor"/>
          <line y1="37.6992" x2="59" y2="37.6992" stroke="currentColor" strokeWidth="3"/>
          </svg>
                           
          `,
          bigger: false,
          type: "alphabet",
        },
        {
          id: 7,
          name: "G",
          icon: `<svg width="59" height="40" viewBox="0 0 59 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.0411 13.4406H43.8811V28.7206H40.6411V22.7606C38.4011 27.054 34.7344 29.2006 29.6411 29.2006C25.5078 29.2006 22.0544 27.8406 19.2811 25.1206C16.5078 22.374 15.1211 18.974 15.1211 14.9206C15.1211 10.8673 16.5078 7.48063 19.2811 4.76062C22.0544 2.01396 25.5078 0.640625 29.6411 0.640625C32.8411 0.640625 35.6678 1.48062 38.1211 3.16062C40.5744 4.81396 42.2411 6.98729 43.1211 9.68063H38.3211C37.4411 8.37396 36.2411 7.34729 34.7211 6.60062C33.2011 5.85396 31.5078 5.48062 29.6411 5.48062C26.7078 5.48062 24.2544 6.38729 22.2811 8.20063C20.3078 9.98729 19.3211 12.2273 19.3211 14.9206C19.3211 17.614 20.3078 19.8673 22.2811 21.6806C24.2544 23.4673 26.7078 24.3606 29.6411 24.3606C32.0944 24.3606 34.2278 23.774 36.0411 22.6006C37.8544 21.4273 39.0544 19.8806 39.6411 17.9606H30.0411V13.4406Z" fill="currentColor"/>
          <line y1="37.7012" x2="59" y2="37.7012" stroke="currentColor" strokeWidth="3"/>
          </svg>
                    
          `,
          bigger: true,
          type: "alphabet",
        },
        {
          id: 8,
          name: "H",
          icon: `<svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M36.96 0.599609H41V28.1996H36.96V16.7996H22.04V28.1996H18V0.599609H22.04V11.8396H36.96V0.599609Z" fill="currentColor"/>
          <line y1="36.6992" x2="59" y2="36.6992" stroke="currentColor" strokeWidth="3"/>
          </svg>
                    
          `,
          bigger: true,
          type: "alphabet",
        },
        {
          id: 9,
          name: "i",
          icon: `<svg width="59" height="40" viewBox="0 0 59 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.5206 5.32008C28.694 5.32008 28.0006 5.08008 27.4406 4.60008C26.9073 4.09341 26.6406 3.46675 26.6406 2.72008C26.6406 1.97341 26.9073 1.34674 27.4406 0.840078C28.0006 0.333411 28.694 0.0800781 29.5206 0.0800781C30.3206 0.0800781 30.9873 0.333411 31.5206 0.840078C32.0806 1.34674 32.3606 1.97341 32.3606 2.72008C32.3606 3.46675 32.0806 4.09341 31.5206 4.60008C30.9873 5.08008 30.3206 5.32008 29.5206 5.32008ZM27.6006 29.2001V8.80008H31.4006V29.2001H27.6006Z" fill="currentColor"/>
          <line y1="37.6992" x2="59" y2="37.6992" stroke="currentColor" strokeWidth="3"/>
          </svg>
                            
          `,
          bigger: false,
          type: "alphabet",
        },
        {
          id: 10,
          name: "j",
          icon: `<svg width="59" height="48" viewBox="0 0 59 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M33.1805 4.52C32.6471 5 31.9671 5.24 31.1405 5.24C30.3138 5.24 29.6205 5 29.0605 4.52C28.5271 4.01333 28.2605 3.38667 28.2605 2.64C28.2605 1.89333 28.5271 1.26667 29.0605 0.76C29.6205 0.253333 30.3138 0 31.1405 0C31.9671 0 32.6471 0.253333 33.1805 0.76C33.7405 1.26667 34.0205 1.89333 34.0205 2.64C34.0205 3.38667 33.7405 4.01333 33.1805 4.52ZM24.9805 37.2V32.68C27.8338 32.68 29.2605 31.4933 29.2605 29.12V8.72H33.0205V29.56C33.0205 34.6533 30.3405 37.2 24.9805 37.2Z" fill="currentColor"/>
          <line y1="45.6992" x2="59" y2="45.6992" stroke="currentColor" strokeWidth="3"/>
          </svg>
                                     
          `,
          bigger: false,
          type: "alphabet",
        },
      ],
      numbers: [
        {
          id: 1,
          name: "1",
          icon: `
          <svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.7998 0.679688H35.1598V28.2797H31.1198V6.27969L23.8398 9.79969V4.59969L31.7998 0.679688Z" fill="currentColor"/>
          <line y1="36.7793" x2="59" y2="36.7793" stroke="currentColor" strokeWidth="3"/>
          </svg>
          `,
          type: "number",
        },
        {
          id: 2,
          name: "2",
          icon: `
          <svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.3183 23.7995H38.7583V28.5195H20.2383V24.7595L31.1983 14.1595C33.2516 12.1595 34.2783 10.5061 34.2783 9.19945C34.2783 8.10612 33.8249 7.21279 32.9183 6.51945C32.0383 5.79945 30.9049 5.43945 29.5183 5.43945C28.0783 5.43945 26.8916 5.79945 25.9583 6.51945C25.0516 7.23945 24.5983 8.14612 24.5983 9.23945H20.3983C20.3983 6.73279 21.2649 4.63945 22.9983 2.95945C24.7316 1.27945 26.8916 0.439453 29.4783 0.439453C32.0383 0.439453 34.1716 1.25279 35.8783 2.87945C37.6116 4.50612 38.4783 6.53279 38.4783 8.95945C38.4783 10.4261 38.1316 11.7461 37.4383 12.9195C36.7716 14.0928 35.8516 15.2661 34.6783 16.4395L27.3183 23.7995Z" fill="currentColor"/>
<line y1="37.0195" x2="59" y2="37.0195" stroke="currentColor" strokeWidth="3"/>
</svg>

          `,
          type: "number",
        },
        {
          id: 3,
          name: "3",
          icon: `
          <svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.9608 13.9192C36.2674 14.5592 37.2941 15.4792 38.0408 16.6792C38.8141 17.8526 39.2008 19.1726 39.2008 20.6392C39.2008 22.9592 38.2674 24.8926 36.4008 26.4392C34.5608 27.9859 32.2674 28.7592 29.5208 28.7592C26.7741 28.7592 24.4674 27.9459 22.6008 26.3192C20.7341 24.6926 19.8008 22.6792 19.8008 20.2792H24.0008C24.0008 21.3459 24.5074 22.2259 25.5208 22.9192C26.5608 23.5859 27.8674 23.9192 29.4408 23.9192C31.0941 23.9192 32.4408 23.5592 33.4808 22.8392C34.5208 22.1192 35.0408 21.1859 35.0408 20.0392C35.0408 18.8926 34.5608 17.9592 33.6008 17.2392C32.6408 16.4926 31.4008 16.1192 29.8808 16.1192H25.5208V11.9192H29.8808C31.2408 11.9192 32.3341 11.5992 33.1608 10.9592C34.0141 10.2926 34.4408 9.43922 34.4408 8.39922C34.4408 7.41255 33.9741 6.61255 33.0408 5.99922C32.1341 5.35922 30.9608 5.03922 29.5208 5.03922C28.0808 5.03922 26.8941 5.34589 25.9608 5.95922C25.0274 6.54589 24.5608 7.30589 24.5608 8.23922H20.4008C20.4008 5.94589 21.2674 4.03922 23.0008 2.51922C24.7341 0.972552 26.9074 0.199219 29.5208 0.199219C32.1341 0.199219 34.3074 0.932552 36.0408 2.39922C37.7741 3.83922 38.6408 5.65255 38.6408 7.83922C38.6408 9.11922 38.3074 10.2926 37.6408 11.3592C37.0008 12.4259 36.1074 13.2792 34.9608 13.9192Z" fill="currentColor"/>
<line y1="37.2598" x2="59" y2="37.2598" stroke="currentColor" strokeWidth="3"/>
</svg>


          `,
          type: "number",
        },
        {
          id: 4,
          name: "4",
          icon: `
          <svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.9591 18.1597V22.7597H36.5191V28.2797H32.4791V22.7597H19.0391V19.0397L30.6391 0.679688H36.5191V18.1597H39.9591ZM23.7991 18.1597H32.4791V4.95969L23.7991 18.1597Z" fill="currentColor"/>
          <line y1="36.7793" x2="59" y2="36.7793" stroke="currentColor" strokeWidth="3"/>
          </svg>
          
          `,
          type: "number",
        },
        {
          id: 5,
          name: "5",
          icon: `
          <svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.8991 10.2795C32.5924 10.2795 34.8591 11.1595 36.6991 12.9195C38.5391 14.6528 39.4591 16.8128 39.4591 19.3995C39.4591 21.9861 38.4991 24.1595 36.5791 25.9195C34.6857 27.6528 32.3124 28.5195 29.4591 28.5195C26.7924 28.5195 24.5257 27.7595 22.6591 26.2395C20.8191 24.7195 19.7791 22.7861 19.5391 20.4395H23.7791C24.0991 21.3995 24.7791 22.1861 25.8191 22.7995C26.8591 23.3861 28.0724 23.6795 29.4591 23.6795C31.1124 23.6795 32.4857 23.2661 33.5791 22.4395C34.6991 21.5861 35.2591 20.5328 35.2591 19.2795C35.2591 18.0261 34.6991 16.9861 33.5791 16.1595C32.4857 15.3061 31.1124 14.8795 29.4591 14.8795C28.4457 14.8795 27.4457 15.0661 26.4591 15.4395C25.4724 15.7861 24.7391 16.2261 24.2591 16.7595H19.9791L21.4191 0.439453H37.6991V5.15945H24.9391L24.4591 11.9995C25.9524 10.8528 27.7657 10.2795 29.8991 10.2795Z" fill="currentColor"/>
          <line y1="37.0195" x2="59" y2="37.0195" stroke="currentColor" strokeWidth="3"/>
          </svg>          
          `,
          type: "number",
        },
        {
          id: 6,
          name: "6",
          icon: `
          <svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.5389 10.5593C33.1256 10.5593 35.2856 11.4126 37.0189 13.1193C38.7522 14.7993 39.6189 16.906 39.6189 19.4393C39.6189 22.0793 38.6856 24.2793 36.8189 26.0393C34.9522 27.7993 32.5922 28.6793 29.7389 28.6793C26.6722 28.6793 24.1789 27.5993 22.2589 25.4393C20.3389 23.2526 19.3789 20.3593 19.3789 16.7593C19.3789 12.0126 20.9256 8.0793 24.0189 4.9593C27.1122 1.8393 31.1122 0.279297 36.0189 0.279297V4.9993C32.8989 4.9993 30.2589 5.77263 28.0989 7.3193C25.9389 8.86596 24.6056 10.9593 24.0989 13.5993C24.8189 12.666 25.7522 11.9326 26.8989 11.3993C28.0456 10.8393 29.2589 10.5593 30.5389 10.5593ZM29.5789 23.8393C31.2322 23.8393 32.6189 23.4393 33.7389 22.6393C34.8856 21.8126 35.4589 20.786 35.4589 19.5593C35.4589 18.3326 34.9122 17.306 33.8189 16.4793C32.7256 15.6526 31.3656 15.2393 29.7389 15.2393C28.0856 15.2393 26.6989 15.6526 25.5789 16.4793C24.4589 17.306 23.8989 18.3326 23.8989 19.5593C23.8989 20.786 24.4456 21.8126 25.5389 22.6393C26.6322 23.4393 27.9789 23.8393 29.5789 23.8393Z" fill="currentColor"/>
          <line y1="37.1797" x2="59" y2="37.1797" stroke="currentColor" strokeWidth="3"/>
          </svg>`,
          type: "number",
        },
        {
          id: 7,
          name: "7",
          icon: `
          <svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.9805 0.679688H39.0205V4.31969L27.5405 28.2797H22.2205L34.1805 5.39969H19.9805V0.679688Z" fill="currentColor"/>
          <line y1="36.7793" x2="59" y2="36.7793" stroke="currentColor" strokeWidth="3"/>
          </svg>
          `,
          type: "number",
        },
        {
          id: 8,
          name: "8",
          icon: `
          <svg width="59" height="39" viewBox="0 0 59 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M35.1789 13.9592C36.5389 14.6792 37.6189 15.6259 38.4189 16.7992C39.2189 17.9459 39.6189 19.2259 39.6189 20.6392C39.6189 22.9326 38.6456 24.8659 36.6989 26.4392C34.7522 27.9859 32.3389 28.7592 29.4589 28.7592C26.6056 28.7592 24.2056 27.9859 22.2589 26.4392C20.3389 24.8926 19.3789 22.9592 19.3789 20.6392C19.3789 19.2259 19.7656 17.9459 20.5389 16.7992C21.3389 15.6259 22.4189 14.6792 23.7789 13.9592C21.2722 12.4926 20.0189 10.4392 20.0189 7.79922C20.0189 5.63922 20.9122 3.83922 22.6989 2.39922C24.5122 0.932552 26.7656 0.199219 29.4589 0.199219C32.1789 0.199219 34.4322 0.932552 36.2189 2.39922C38.0322 3.83922 38.9389 5.63922 38.9389 7.79922C38.9389 10.4126 37.6856 12.4659 35.1789 13.9592ZM29.4589 5.03922C27.9389 5.03922 26.6856 5.34589 25.6989 5.95922C24.7122 6.57255 24.2189 7.34589 24.2189 8.27922C24.2189 9.26589 24.7122 10.0792 25.6989 10.7192C26.6856 11.3592 27.9389 11.6792 29.4589 11.6792C31.0056 11.6792 32.2722 11.3592 33.2589 10.7192C34.2456 10.0792 34.7389 9.26589 34.7389 8.27922C34.7389 7.34589 34.2456 6.57255 33.2589 5.95922C32.2722 5.34589 31.0056 5.03922 29.4589 5.03922ZM29.4589 23.9192C31.1922 23.9192 32.6189 23.5726 33.7389 22.8792C34.8589 22.1859 35.4189 21.2926 35.4189 20.1992C35.4189 19.0792 34.8589 18.1592 33.7389 17.4392C32.6189 16.7192 31.1922 16.3592 29.4589 16.3592C27.7256 16.3592 26.2989 16.7192 25.1789 17.4392C24.0856 18.1592 23.5389 19.0792 23.5389 20.1992C23.5389 21.2926 24.0989 22.1859 25.2189 22.8792C26.3389 23.5726 27.7522 23.9192 29.4589 23.9192Z" fill="currentColor"/>
          <line y1="37.2598" x2="59" y2="37.2598" stroke="currentColor" strokeWidth="3"/>
          </svg>`,
          type: "number",
        },
      ],
      symbols: [
        {
          id: 1,
          name: "!",
          icon: `
          <svg width="60" height="39" viewBox="0 0 60 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.6416 19.4395L28.0016 7.23945V0.439453H32.0416V7.23945L31.4016 19.4395H28.6416ZM32.4416 27.4795C31.8016 28.0661 30.9882 28.3595 30.0016 28.3595C29.0149 28.3595 28.2016 28.0661 27.5616 27.4795C26.9216 26.8661 26.6016 26.1195 26.6016 25.2395C26.6016 24.3328 26.9216 23.5861 27.5616 22.9995C28.2016 22.3861 29.0149 22.0795 30.0016 22.0795C30.9882 22.0795 31.8016 22.3861 32.4416 22.9995C33.0816 23.5861 33.4016 24.3328 33.4016 25.2395C33.4016 26.1195 33.0816 26.8661 32.4416 27.4795Z" fill="currentColor"/>
<line x1="0.5" y1="36.8594" x2="59.5" y2="36.8594" stroke="currentColor" strokeWidth="3"/>
</svg>
`,
          type: "symbol",
        },
        {
          id: 2,
          name: "+",
          icon: `
          <svg width="60" height="28" viewBox="0 0 60 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M38.2788 6.91883V11.1988H31.6788V17.3588H28.3188V11.1988H21.7188V6.91883H28.3188V0.798828H31.6788V6.91883H38.2788Z" fill="currentColor"/>
          <line x1="0.5" y1="25.8594" x2="59.5" y2="25.8594" stroke="currentColor" strokeWidth="3"/>
          </svg>
          
`,
          type: "symbol",
        },
        {
          id: 3,
          name: "%",
          icon: `
          <svg width="60" height="40" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.4388 13.9588C19.5188 13.9588 17.9188 13.3322 16.6388 12.0788C15.3588 10.8255 14.7188 9.25216 14.7188 7.35883C14.7188 5.49216 15.3588 3.93216 16.6388 2.67883C17.9188 1.42549 19.5188 0.798828 21.4388 0.798828C23.3321 0.798828 24.9188 1.42549 26.1988 2.67883C27.4788 3.93216 28.1188 5.49216 28.1188 7.35883C28.1188 9.22549 27.4788 10.7988 26.1988 12.0788C24.9188 13.3322 23.3321 13.9588 21.4388 13.9588ZM17.9588 28.8788L37.9587 1.27883H42.1188L22.1188 28.8788H17.9588ZM21.4388 10.1188C22.4254 10.1188 23.2521 9.86549 23.9187 9.35883C24.5854 8.82549 24.9187 8.15883 24.9187 7.35883C24.9187 6.58549 24.5854 5.94549 23.9187 5.43883C23.2521 4.9055 22.4254 4.63883 21.4388 4.63883C20.4254 4.63883 19.5854 4.9055 18.9187 5.43883C18.2521 5.94549 17.9188 6.58549 17.9188 7.35883C17.9188 8.15883 18.2521 8.82549 18.9187 9.35883C19.5854 9.86549 20.4254 10.1188 21.4388 10.1188ZM43.3588 27.4788C42.0788 28.7322 40.4921 29.3588 38.5988 29.3588C36.7054 29.3588 35.1187 28.7322 33.8388 27.4788C32.5588 26.1988 31.9188 24.6255 31.9188 22.7588C31.9188 20.8922 32.5588 19.3322 33.8388 18.0788C35.1187 16.8255 36.7054 16.1988 38.5988 16.1988C40.4921 16.1988 42.0788 16.8255 43.3588 18.0788C44.6388 19.3322 45.2788 20.8922 45.2788 22.7588C45.2788 24.6255 44.6388 26.1988 43.3588 27.4788ZM36.1187 24.7188C36.7854 25.2522 37.6121 25.5188 38.5988 25.5188C39.5854 25.5188 40.4121 25.2522 41.0788 24.7188C41.7454 24.1855 42.0788 23.5322 42.0788 22.7588C42.0788 21.9855 41.7454 21.3455 41.0788 20.8388C40.4121 20.3055 39.5854 20.0388 38.5988 20.0388C37.6121 20.0388 36.7854 20.3055 36.1187 20.8388C35.4521 21.3455 35.1188 21.9855 35.1188 22.7588C35.1188 23.5322 35.4521 24.1855 36.1187 24.7188Z" fill="currentColor"/>
          <line x1="0.5" y1="37.8594" x2="59.5" y2="37.8594" stroke="currentColor" strokeWidth="3"/>
          </svg>
                    
`,
          type:"symbol"
        },
        {
          id: 4,
          name: "%",
          icon: `
          <svg width="60" height="40" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M42.0614 28.8788H37.9414L35.3414 25.9988C33.6081 28.2388 31.1414 29.3588 27.9414 29.3588C25.0081 29.3588 22.6081 28.5322 20.7414 26.8788C18.8747 25.2255 17.9414 23.1055 17.9414 20.5188C17.9414 17.0522 19.6481 14.3188 23.0614 12.3188C21.4347 10.2655 20.6214 8.42549 20.6214 6.79883C20.6214 5.09216 21.2747 3.66549 22.5814 2.51883C23.9147 1.37216 25.5547 0.798828 27.5014 0.798828C29.4481 0.798828 31.0747 1.38549 32.3814 2.55883C33.7147 3.70549 34.3814 5.15883 34.3814 6.91883C34.3814 9.69216 32.4347 12.0388 28.5414 13.9588L35.1414 21.2388C35.6214 20.4122 35.8614 19.4922 35.8614 18.4788V15.3188H39.5014V18.4788C39.5014 20.5322 38.8881 22.3855 37.6614 24.0388L42.0614 28.8788ZM27.3414 4.71883C26.3814 4.71883 25.5681 4.94549 24.9014 5.39883C24.2347 5.85216 23.9014 6.47883 23.9014 7.27883C23.9014 8.13216 24.5547 9.3455 25.8614 10.9188C29.1147 9.5055 30.7414 8.18549 30.7414 6.95883C30.7414 6.31883 30.4214 5.78549 29.7814 5.35883C29.1414 4.93216 28.3281 4.71883 27.3414 4.71883ZM28.1414 24.8788C29.9814 24.8788 31.6081 24.4122 33.0214 23.4788L25.7014 15.3988C23.1414 16.8122 21.8614 18.4388 21.8614 20.2788C21.8614 21.6122 22.4481 22.7188 23.6214 23.5988C24.8214 24.4522 26.3281 24.8788 28.1414 24.8788Z" fill="currentColor"/>
          <line x1="0.5" y1="37.8594" x2="59.5" y2="37.8594" stroke="currentColor" strokeWidth="3"/>
          </svg>
                              
`,
type:"symbol"
        },
        {
          id: 5,
          name: "/",
          icon: `
          <svg width="60" height="47" viewBox="0 0 60 47" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.1016 36.3594L36.1016 0.359375H39.9016L23.9016 36.3594H20.1016Z" fill="currentColor"/>
          <line x1="0.5" y1="44.8594" x2="59.5" y2="44.8594" stroke="currentColor" strokeWidth="3"/>
          </svg>
                                        
`,
type:"symbol"
        },
        {
          id: 6,
          name: "(",
          icon: `
          <svg width="60" height="49" viewBox="0 0 60 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.5792 38.3598C28.8192 36.0931 27.3926 33.2531 26.2992 29.8398C25.2326 26.3998 24.6992 22.9731 24.6992 19.5598C24.6992 16.1464 25.2326 12.7331 26.2992 9.31977C27.3926 5.87977 28.8192 3.02643 30.5792 0.759766H35.2992C33.3259 3.02643 31.7259 5.87977 30.4992 9.31977C29.2992 12.7331 28.6992 16.1464 28.6992 19.5598C28.6992 22.9731 29.2992 26.3998 30.4992 29.8398C31.7259 33.2531 33.3259 36.0931 35.2992 38.3598H30.5792Z" fill="currentColor"/>
            <line x1="0.5" y1="46.8594" x2="59.5" y2="46.8594" stroke="currentColor" strokeWidth="3"/>
          </svg>                                  
          `,
          type:"symbol"
        },
        {
          id: 7,
          name: "=",
          icon: `
          <svg width="60" height="23" viewBox="0 0 60 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.7188 4.47922V0.199219H38.2788V4.47922H21.7188ZM21.7188 12.3592V8.07922H38.2788V12.3592H21.7188Z" fill="currentColor"/>
          <line x1="0.5" y1="20.8594" x2="59.5" y2="20.8594" stroke="currentColor" strokeWidth="3"/>
          </svg>`,
          type:"symbol"
        },
      ],
    },
  },
  {
    id:10,
    question:"Aşağıda yer alan şeklin yukarıdan görünümü aşağıdakilerden hangisidir?",
    picture:"question.png",
    type:"image-question",
    rightAnswer:10,
    answers:[
      {
        id: 1,
        answer: `answer-one.png`,
      },
      {
        id: 2,
        answer: `answer-two.png`,
      },
      {
        id: 3,
        answer: `answer-three.png`,
      },
      {
        id: 4,
        answer: `answer-four.png`,
      },
      {
        id: 5,
        answer: `answer-five.png`,
      },
      {
        id: 6,
        answer: `answer-six.png`,
      },
      {
        id: 7,
        answer: `answer-seven.png`,
      },
      {
        id: 8,
        answer: `answer-eight.png`,
      },
      {
        id: 9,
        answer: `answer-nine.png`,
      },
      {
        id: 10,
        answer: `answer-ten.png`,
      },
      {
        id: 11,
        answer: `answer-eleven.png`,
      },
      {
        id: 12,
        answer: `answer-twelve.png`,
      },
    ]
  },
  {
    id:11,
    question:"Aşağıdaki şekillerden hangisi tam simetriktir?",
    type:"image-question",
    rightAnswer:4,
    answers:[
      {
        id: 1,
        answer: `answer-one.png`,
      },
      {
        id: 2,
        answer: `answer-two.png`,
      },
      {
        id: 3,
        answer: `answer-three.png`,
      },
      {
        id: 4,
        answer: `answer-four.png`,
      },
      {
        id: 5,
        answer: `answer-five.png`,
      },
      {
        id: 6,
        answer: `answer-six.png`,
      },
      {
        id: 7,
        answer: `answer-seven.png`,
      },
      {
        id: 8,
        answer: `answer-eight.png`,
      },
      {
        id: 9,
        answer: `answer-nine.png`,
      },
    ]
  },
  {
    id:12,
    questions:[
      {
        id:1,
        question:`Aşağıdaki şıklarda yer alan şekillerden hangilerini kullanırsak yukarıdaki gibi dikdörtgen elde ederiz? (Şekilleri 90° açı ile döndürerek kullanabilirsiniz)`,
        questionImage:`question-one.png`,
        rightAnswer:1,
        answers:[
          {
            id:1,
            answerImage:"answer-one-one.png"
          },
          {
            id:2,
            answerImage:"answer-one-two.png"
          },
          {
            id:3,
            answerImage:"answer-one-three.png"
          },
          {
            id:4,
            answerImage:"answer-one-four.png"
          }
        ]
      },
      {
        id:2,
        question:`Aşağıdaki şıklarda yer alan şekillerden hangilerini kullanırsak yukarıdaki gibi dikdörtgen elde ederiz? (Şekilleri 90° açı ile döndürerek kullanabilirsiniz)`,
        questionImage:`question-two.png`,
        rightAnswer:3,
        answers:[
          {
            id:1,
            answerImage:"answer-two-one.png"
          },
          {
            id:2,
            answerImage:"answer-two-two.png"
          },
          {
            id:3,
            answerImage:"answer-two-three.png"
          },
          {
            id:4,
            answerImage:"answer-two-four.png"
          }
        ]
      },
      {
        id:3,
        question:`Yukarıda yer alan şekiller ile aşağıdaki şıklarda yer alan şekillerden hangisi yapılabilir? (Şekilleri 90° açı ile döndürerek kullanabilirsiniz)`,
        questionImage:`question-three.png`,
        rightAnswer:2,
        answers:[
          {
            id:1,
            answerImage:"answer-three-one-new.png"
          },
          {
            id:2,
            answerImage:"answer-three-two-new.png"
          },
          {
            id:3,
            answerImage:"answer-three-three-new.png"
          },
          {
            id:4,
            answerImage:"answer-three-four-new.png"
          }
        ]
      },
      {
        id:4,
        question:`Yukarıda yer alan şekiller ile aşağıdaki şıklarda yer alan şekillerden hangisi yapılabilir? (Şekilleri 90° açı ile döndürerek kullanabilirsiniz)`,
        questionImage:`question-four.png`,
        rightAnswer:4,
        answers:[
          {
            id:1,
            answerImage:"answer-four-one-new.png"
          },
          {
            id:2,
            answerImage:"answer-four-two-new.png"
          },
          {
            id:3,
            answerImage:"answer-four-three-new.png"
          },
          {
            id:4,
            answerImage:"answer-four-four-new.png"
          }
        ]
      }
    ]
  }
];

export const homeQuestionsData = [
  {
    id: 3,
    point: 0,
    question: `Edutest'e neden ihtiyacım var?`,
    answers: [
      {
        id: uuidv4(),
        answer: `Kendimle alakalı detaylı bir test çözmek istiyorum.`,
      },
      {
        id: uuidv4(),
        answer: `Geleceğim için neler yapmalıyım öğrenmek istiyorum.`,
      },
      {
        id: uuidv4(),
        answer: `Kişiliğime ve ilgi alanlarıma uygun bölümleri öğrenmek istiyorum.`,
      },
      {
        id: uuidv4(),
        answer: `Üniversite tercihimi hangi bölümden yana kullanmalıyım bilmiyorum.`,
      },
     
    ],
    type: "choice",
  },
]
