import React, { useState } from "react";
import QuestionsHeader from "../Layout/QuestionsHeader";
import { useQuestionContext } from "../../context/QuestionContext";

const DynamicQuestions = () => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const { activeStep, newQuestion, nextQuestionRequest, photoQuestion } =
    useQuestionContext();
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const removeCheck = () => {
    document.querySelectorAll(".form-check-input").forEach((check) => {
      check.checked = false;
    });
  };
  const nextQuestion = () => {
    const data = {
      question: newQuestion.next_question,
      answer: selectedAnswer.id,
      point: selectedAnswer.point,
    };
    setSelectedAnswer("");
    removeCheck();
    nextQuestionRequest(data);
  };
  console.log(photoQuestion);
  return (
    <div className="before-questions w-100">
      <QuestionsHeader />

      <div className="container ">
        <div className="question-area dynamic-area">
          <div className="d-flex flex-column  justify-content-center h-100 w-100">
            <h6 className="question-q text-start d-flex gap-2">
              <span>{activeStep + 1}.</span>{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: newQuestion.question_data.name,
                }}
              ></span>
            </h6>
            <div
              className={`d-flex   w-100 mt-3 ${
                !photoQuestion ? " flex-column gap-2" : "row"
              }`}
            >
              {!photoQuestion
                ? newQuestion.answer_data.map((answer, key) => (
                    <div className="form-check  ps-0" key={key}>
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="flexRadioDefault"
                        id={answer.id}
                        onChange={() => setSelectedAnswer(answer)}
                      />
                      <label
                        className={`form-check-label answers-check `}
                        htmlFor={answer.id}
                      >
                        <p className="mb-0">
                          <span className="alphabet">{alphabet[key]}.</span>{" "}
                          {answer.name}
                        </p>
                      </label>
                    </div>
                  ))
                : newQuestion.answer_data.map((answer, key) => (
                    <div className="col-lg-4 mb-5 ">
                      <div className="form-check form-check-photo  ps-0" key={key}>
                        <input
                          className="form-check-input form-check-input-photo d-none"
                          type="radio"
                          name="flexRadioDefault"
                          id={answer.id}
                          onChange={() => setSelectedAnswer(answer)}
                        />
                        <label
                          className={`form-check-label answers-check answer-check-photo`}
                          htmlFor={answer.id}
                        >
                          <p className="mb-0 d-flex align-items-start gap-2 pb-2">
                            <span className="check-circle"></span>{" "}
                            {answer.name}
                          </p>
                         <span class="span-image-container">
                         <img src={answer.photo} alt="" />
                         </span>
                        </label>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center w-100 question-fixed-bottom">
        <button
          className={`button-shadow ${
            !selectedAnswer ? "shadow-gray" : "shadow-blue"
          }`}
          disabled={!selectedAnswer}
          onClick={nextQuestion}
        >
          İlerle
        </button>
      </div>
    </div>
  );
};

export default DynamicQuestions;
