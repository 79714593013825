import React, { useEffect } from "react";
import FormStepper from "../FormStepper";
import { useQuestionContext } from "../../context/QuestionContext";
import MovingCirclesCanvas from "../Steps/MovingCirclesCanvas";
import ProblemSolving from "../Steps/ProblemSolving";
import ChoiceQuestion from "../ChoiceQuestion";
import ImageChoiceQuestion from "../ImageChoiceQuestion";
import DrawRoute from "../Steps/DrawRoute";
import "./questions.scss";
import "../../assets/scss/beforeQuestions.scss"
import Arrow from "../Steps/Arrow";
import PasswordGenerate from "../Steps/PasswordGenerate";
import GridImageContext from "../Steps/GridImageContext";
import CheckImageChoiceQuestion from "../Steps/CheckImageChoiceQuestion";
import DynamicQuestions from "./DynamicQuestions";
import MultipleQuestion from "../Steps/MultipleQuestion";
import { Link, useNavigate } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { handleStartQuizChangeState } from "../../store/user";
const QuestionsTwoPage = () => {
  const { activeStep, questionsData } = useQuestionContext();
  const dispatch = useDispatch();
  const { quiz_id } = useSelector((state) => state.user);

  const handleStartQuiz = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApi()
      .get("quiz/new")
      .then((res) => {
        if (res.data.status) {
          dispatch(handleStartQuizChangeState(res.data.quiz_id));
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    handleStartQuiz()
  }, []);
  return (
    <>
    <div className="questions d-lg-block d-none">
      {activeStep <= 10 ?<FormStepper activeStep={activeStep}>
        <MovingCirclesCanvas />
        <ProblemSolving data={questionsData[1]} />
        <ChoiceQuestion data={questionsData[2]} />
        <ChoiceQuestion data={questionsData[3]} />
        <Arrow data={questionsData[6]} />
        <MultipleQuestion data={questionsData[10]} />
        <DrawRoute data={questionsData[5]} />
        <PasswordGenerate data={questionsData[7]} />
        <ImageChoiceQuestion data={questionsData[4]} />
        <GridImageContext data={questionsData[8]} />
        <CheckImageChoiceQuestion data={questionsData[9]} />
       
      </FormStepper> : 
      <DynamicQuestions />
      }
    </div>
    <div className="d-lg-none d-flex flex-column w-100 vh-100 align-items-center justify-content-center">
      <p className="text-white text-center px-3">
        Mobil cihazlar üzerinden teste erişemezsiniz. Lütfen bilgisayar kullanınız.
      </p>
      <Link to="/" className="button-shadow shadow-blue mt-3">Anasayfaya Dön</Link>
    </div>
    </>
  );
};

export default QuestionsTwoPage;
