/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import { Container } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import JobReportText from "../components/JobReportText";
import { useSelector } from "react-redux";

const JobReport = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const { userData } = useSelector((state) => state.user);
  const [quizData, setQuizData] = useState([]);
  const [unluler,setUnluler] = useState([])
  useEffect(() => {
    useApi()
      .get("quiz/getmeslekdetail?meslek_id=" + id)
      .then((res) => {
        setUnluler(res.data.unlu)
        setJob(res.data.data);
      });
    useApi()
      .get(`account/accountInfo?target_student=${userData.id}`)
      .then((res) => {
        setQuizData(res.data?.student);
      });
  }, [id]);

  return (
    <Fragment>
      {job && (
        <div className="fixed-page-wrapper ">
          <Container className="py-5">
            <h3 className="text-center">{job?.name}</h3>
            <div className="py-3  ">
              <Swiper modules={[Navigation]} slidesPerView={1} navigation>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="report-paper d-flex flex-column">
                    <div className="report-header d-flex justify-content-center">
                      <Link className="mx-auto" to="/">
                        <span>
                          <svg
                            width="150"
                            height="33"
                            viewBox="0 0 150 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16" cy="16.5" r="16" fill="#58CC02" />
                            <path
                              d="M22.6396 14.8586V17.3644H14.0312C14.0312 15.9806 15.1532 14.8586 16.537 14.8586H22.6396Z"
                              fill="white"
                            />
                            <path
                              d="M9.90421 10.0087V22.2143H13.5014C14.8852 22.2143 16.0072 23.3359 16.0072 24.7197C16.0072 23.3359 17.1292 22.2143 18.513 22.2143H24.6156V24.7197H18.513C17.1292 24.7197 16.0072 25.8417 16.0072 27.2258C16.0072 25.8417 14.8852 24.7197 13.5014 24.7197H7.39844V7.50293H13.5014C14.8852 7.50293 16.0072 8.62455 16.0072 10.0087C16.0072 8.62455 17.1292 7.50293 18.513 7.50293H24.6156V10.0087H18.513C17.1292 10.0087 16.0072 11.1307 16.0072 12.5145C16.0072 11.1307 14.8852 10.0087 13.5014 10.0087H9.90421Z"
                              fill="white"
                            />
                            <path
                              d="M56.8767 19.2869C56.8767 19.6876 56.8497 20.0519 56.7956 20.3798H45.3811C45.6335 21.1448 46.1294 21.755 46.8687 22.2104C47.6261 22.6658 48.5187 22.8934 49.5465 22.8934C51.1514 22.8934 52.3235 22.52 53.0629 21.7732H56.6874C56.1464 23.194 55.2267 24.3415 53.9284 25.2158C52.6301 26.0719 51.1424 26.5 49.4654 26.5C47.3376 26.5 45.5614 25.8078 44.1368 24.4235C42.7123 23.0209 42 21.2814 42 19.2049C42 17.1284 42.7033 15.398 44.1098 14.0137C45.5343 12.6111 47.3105 11.9098 49.4384 11.9098C51.5662 11.9098 53.3333 12.6111 54.7399 14.0137C56.1644 15.398 56.8767 17.1557 56.8767 19.2869ZM52.008 16.1995C51.2686 15.7441 50.4121 15.5164 49.4384 15.5164C48.4646 15.5164 47.6081 15.7441 46.8687 16.1995C46.1474 16.6548 45.6516 17.2559 45.3811 18.0027H53.5227C53.2702 17.2377 52.7653 16.6366 52.008 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M71.2522 6.5H74.5521V26.1721H71.2522V23.7678C70.0981 25.5893 68.4932 26.5 66.4375 26.5C64.5441 26.5 62.9573 25.8078 61.677 24.4235C60.4147 23.0209 59.7836 21.2814 59.7836 19.2049C59.7836 17.1284 60.4147 15.398 61.677 14.0137C62.9573 12.6111 64.5441 11.9098 66.4375 11.9098C68.4932 11.9098 70.0981 12.8206 71.2522 14.6421V6.5ZM64.3548 21.6093C65.1121 22.2468 66.0679 22.5656 67.2219 22.5656C68.376 22.5656 69.3317 22.2468 70.0891 21.6093C70.8645 20.9718 71.2522 20.1703 71.2522 19.2049C71.2522 18.2395 70.8645 17.4381 70.0891 16.8005C69.3317 16.163 68.376 15.8443 67.2219 15.8443C66.0679 15.8443 65.1121 16.163 64.3548 16.8005C63.5974 17.4381 63.2187 18.2395 63.2187 19.2049C63.2187 20.1703 63.5974 20.9718 64.3548 21.6093Z"
                              fill="white"
                            />
                            <path
                              d="M88.3952 12.2377H91.7222V26.1721H88.3952V23.7404C87.3494 25.5801 85.8256 26.5 83.824 26.5C82.3273 26.5 81.1102 26.0264 80.1725 25.0792C79.2528 24.1138 78.793 22.8479 78.793 21.2814V12.2377H82.0929V20.0792C82.0929 20.826 82.3724 21.4271 82.9314 21.8825C83.4904 22.3379 84.2298 22.5656 85.1494 22.5656C86.1232 22.5656 86.9076 22.3288 87.5026 21.8552C88.0977 21.3816 88.3952 20.7714 88.3952 20.0246V12.2377Z"
                              fill="white"
                            />
                            <path
                              d="M104.921 16.0902H101.134V20.8716C101.134 21.8188 101.648 22.2923 102.676 22.2923H104.65V26.1721H102.324C99.3128 26.1721 97.8071 24.724 97.8071 21.8279V16.0902H95.1293V12.2377H97.8071V9.01366L101.134 8.13934V12.2377H104.921V16.0902Z"
                              fill="white"
                            />
                            <path
                              d="M122.274 19.2869C122.274 19.6876 122.247 20.0519 122.193 20.3798H110.778C111.031 21.1448 111.527 21.755 112.266 22.2104C113.023 22.6658 113.916 22.8934 114.944 22.8934C116.549 22.8934 117.721 22.52 118.46 21.7732H122.085C121.544 23.194 120.624 24.3415 119.326 25.2158C118.027 26.0719 116.54 26.5 114.863 26.5C112.735 26.5 110.959 25.8078 109.534 24.4235C108.11 23.0209 107.397 21.2814 107.397 19.2049C107.397 17.1284 108.101 15.398 109.507 14.0137C110.932 12.6111 112.708 11.9098 114.836 11.9098C116.963 11.9098 118.731 12.6111 120.137 14.0137C121.562 15.398 122.274 17.1557 122.274 19.2869ZM117.405 16.1995C116.666 15.7441 115.809 15.5164 114.836 15.5164C113.862 15.5164 113.005 15.7441 112.266 16.1995C111.545 16.6548 111.049 17.2559 110.778 18.0027H118.92C118.668 17.2377 118.163 16.6366 117.405 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M131.429 26.5C129.608 26.5 128.093 26.0173 126.885 25.0519C125.677 24.0683 125.073 22.8752 125.073 21.4727H128.346C128.346 21.9281 128.634 22.3015 129.211 22.5929C129.806 22.8843 130.554 23.0301 131.456 23.0301C132.358 23.0301 133.079 22.9208 133.62 22.7022C134.179 22.4836 134.458 22.1922 134.458 21.8279C134.458 21.5 134.134 21.2359 133.485 21.0355C132.854 20.8352 131.889 20.653 130.591 20.4891C129.04 20.3069 127.768 19.8789 126.777 19.2049C125.785 18.531 125.289 17.5747 125.289 16.3361C125.289 14.9517 125.866 13.8679 127.02 13.0847C128.192 12.3015 129.644 11.9098 131.375 11.9098C133.142 11.9098 134.612 12.3743 135.784 13.3033C136.956 14.2322 137.542 15.3798 137.542 16.7459H134.215C134.215 16.3452 133.945 16.0173 133.404 15.7623C132.863 15.5073 132.186 15.3798 131.375 15.3798C130.545 15.3798 129.869 15.48 129.346 15.6803C128.841 15.8625 128.589 16.1266 128.589 16.4727C128.589 17.0556 129.878 17.5018 132.457 17.8115C135.991 18.2668 137.758 19.6239 137.758 21.8825C137.758 23.3215 137.154 24.4508 135.946 25.2705C134.738 26.0902 133.232 26.5 131.429 26.5Z"
                              fill="white"
                            />
                            <path
                              d="M150 16.0902H146.213V20.8716C146.213 21.8188 146.727 22.2923 147.755 22.2923H149.73V26.1721H147.403C144.392 26.1721 142.886 24.724 142.886 21.8279V16.0902H140.208V12.2377H142.886V9.01366L146.213 8.13934V12.2377H150V16.0902Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </Link>
                    </div>
                    <div className="col">
                      <div className="d-flex justify-content-center flex-column align-items-center  h-100">
                        <div className="my-auto">
                          <h6 className="jobreport-name text-center">- {quizData.name} -</h6>
                          <h2 className="report-title text-center">
                            Raporunuz <br /> Oluşturuldu
                          </h2>
                          <p className="report-text text-center d-flex flex-column">
                            Daha Fazlasını Öğren
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                              >
                                <path
                                  d="M18.4207 8.17993H12.1907H6.58072C5.62072 8.17993 5.14073 9.33993 5.82073 10.0199L11.0007 15.1999C11.8307 16.0299 13.1807 16.0299 14.0107 15.1999L15.9807 13.2299L19.1907 10.0199C19.8607 9.33993 19.3807 8.17993 18.4207 8.17993Z"
                                  fill="white"
                                  fillOpacity="0.5"
                                />
                              </svg>
                            </span>
                          </p>
                        </div>
                        <div className="mt-auto">
                          <div class="page-title">
                            <h2 class="title">
                              Seni ve <br />
                              geleceğini <br />
                              önemsiyoruz
                            </h2>
                            <img
                              src="/images/main-report.svg"
                              alt=""
                              class="title-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="report-paper d-flex flex-column">
                    <div className="report-header d-flex justify-content-center">
                      <Link className="mx-auto" to="/">
                        <span>
                          <svg
                            width="150"
                            height="33"
                            viewBox="0 0 150 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16" cy="16.5" r="16" fill="#58CC02" />
                            <path
                              d="M22.6396 14.8586V17.3644H14.0312C14.0312 15.9806 15.1532 14.8586 16.537 14.8586H22.6396Z"
                              fill="white"
                            />
                            <path
                              d="M9.90421 10.0087V22.2143H13.5014C14.8852 22.2143 16.0072 23.3359 16.0072 24.7197C16.0072 23.3359 17.1292 22.2143 18.513 22.2143H24.6156V24.7197H18.513C17.1292 24.7197 16.0072 25.8417 16.0072 27.2258C16.0072 25.8417 14.8852 24.7197 13.5014 24.7197H7.39844V7.50293H13.5014C14.8852 7.50293 16.0072 8.62455 16.0072 10.0087C16.0072 8.62455 17.1292 7.50293 18.513 7.50293H24.6156V10.0087H18.513C17.1292 10.0087 16.0072 11.1307 16.0072 12.5145C16.0072 11.1307 14.8852 10.0087 13.5014 10.0087H9.90421Z"
                              fill="white"
                            />
                            <path
                              d="M56.8767 19.2869C56.8767 19.6876 56.8497 20.0519 56.7956 20.3798H45.3811C45.6335 21.1448 46.1294 21.755 46.8687 22.2104C47.6261 22.6658 48.5187 22.8934 49.5465 22.8934C51.1514 22.8934 52.3235 22.52 53.0629 21.7732H56.6874C56.1464 23.194 55.2267 24.3415 53.9284 25.2158C52.6301 26.0719 51.1424 26.5 49.4654 26.5C47.3376 26.5 45.5614 25.8078 44.1368 24.4235C42.7123 23.0209 42 21.2814 42 19.2049C42 17.1284 42.7033 15.398 44.1098 14.0137C45.5343 12.6111 47.3105 11.9098 49.4384 11.9098C51.5662 11.9098 53.3333 12.6111 54.7399 14.0137C56.1644 15.398 56.8767 17.1557 56.8767 19.2869ZM52.008 16.1995C51.2686 15.7441 50.4121 15.5164 49.4384 15.5164C48.4646 15.5164 47.6081 15.7441 46.8687 16.1995C46.1474 16.6548 45.6516 17.2559 45.3811 18.0027H53.5227C53.2702 17.2377 52.7653 16.6366 52.008 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M71.2522 6.5H74.5521V26.1721H71.2522V23.7678C70.0981 25.5893 68.4932 26.5 66.4375 26.5C64.5441 26.5 62.9573 25.8078 61.677 24.4235C60.4147 23.0209 59.7836 21.2814 59.7836 19.2049C59.7836 17.1284 60.4147 15.398 61.677 14.0137C62.9573 12.6111 64.5441 11.9098 66.4375 11.9098C68.4932 11.9098 70.0981 12.8206 71.2522 14.6421V6.5ZM64.3548 21.6093C65.1121 22.2468 66.0679 22.5656 67.2219 22.5656C68.376 22.5656 69.3317 22.2468 70.0891 21.6093C70.8645 20.9718 71.2522 20.1703 71.2522 19.2049C71.2522 18.2395 70.8645 17.4381 70.0891 16.8005C69.3317 16.163 68.376 15.8443 67.2219 15.8443C66.0679 15.8443 65.1121 16.163 64.3548 16.8005C63.5974 17.4381 63.2187 18.2395 63.2187 19.2049C63.2187 20.1703 63.5974 20.9718 64.3548 21.6093Z"
                              fill="white"
                            />
                            <path
                              d="M88.3952 12.2377H91.7222V26.1721H88.3952V23.7404C87.3494 25.5801 85.8256 26.5 83.824 26.5C82.3273 26.5 81.1102 26.0264 80.1725 25.0792C79.2528 24.1138 78.793 22.8479 78.793 21.2814V12.2377H82.0929V20.0792C82.0929 20.826 82.3724 21.4271 82.9314 21.8825C83.4904 22.3379 84.2298 22.5656 85.1494 22.5656C86.1232 22.5656 86.9076 22.3288 87.5026 21.8552C88.0977 21.3816 88.3952 20.7714 88.3952 20.0246V12.2377Z"
                              fill="white"
                            />
                            <path
                              d="M104.921 16.0902H101.134V20.8716C101.134 21.8188 101.648 22.2923 102.676 22.2923H104.65V26.1721H102.324C99.3128 26.1721 97.8071 24.724 97.8071 21.8279V16.0902H95.1293V12.2377H97.8071V9.01366L101.134 8.13934V12.2377H104.921V16.0902Z"
                              fill="white"
                            />
                            <path
                              d="M122.274 19.2869C122.274 19.6876 122.247 20.0519 122.193 20.3798H110.778C111.031 21.1448 111.527 21.755 112.266 22.2104C113.023 22.6658 113.916 22.8934 114.944 22.8934C116.549 22.8934 117.721 22.52 118.46 21.7732H122.085C121.544 23.194 120.624 24.3415 119.326 25.2158C118.027 26.0719 116.54 26.5 114.863 26.5C112.735 26.5 110.959 25.8078 109.534 24.4235C108.11 23.0209 107.397 21.2814 107.397 19.2049C107.397 17.1284 108.101 15.398 109.507 14.0137C110.932 12.6111 112.708 11.9098 114.836 11.9098C116.963 11.9098 118.731 12.6111 120.137 14.0137C121.562 15.398 122.274 17.1557 122.274 19.2869ZM117.405 16.1995C116.666 15.7441 115.809 15.5164 114.836 15.5164C113.862 15.5164 113.005 15.7441 112.266 16.1995C111.545 16.6548 111.049 17.2559 110.778 18.0027H118.92C118.668 17.2377 118.163 16.6366 117.405 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M131.429 26.5C129.608 26.5 128.093 26.0173 126.885 25.0519C125.677 24.0683 125.073 22.8752 125.073 21.4727H128.346C128.346 21.9281 128.634 22.3015 129.211 22.5929C129.806 22.8843 130.554 23.0301 131.456 23.0301C132.358 23.0301 133.079 22.9208 133.62 22.7022C134.179 22.4836 134.458 22.1922 134.458 21.8279C134.458 21.5 134.134 21.2359 133.485 21.0355C132.854 20.8352 131.889 20.653 130.591 20.4891C129.04 20.3069 127.768 19.8789 126.777 19.2049C125.785 18.531 125.289 17.5747 125.289 16.3361C125.289 14.9517 125.866 13.8679 127.02 13.0847C128.192 12.3015 129.644 11.9098 131.375 11.9098C133.142 11.9098 134.612 12.3743 135.784 13.3033C136.956 14.2322 137.542 15.3798 137.542 16.7459H134.215C134.215 16.3452 133.945 16.0173 133.404 15.7623C132.863 15.5073 132.186 15.3798 131.375 15.3798C130.545 15.3798 129.869 15.48 129.346 15.6803C128.841 15.8625 128.589 16.1266 128.589 16.4727C128.589 17.0556 129.878 17.5018 132.457 17.8115C135.991 18.2668 137.758 19.6239 137.758 21.8825C137.758 23.3215 137.154 24.4508 135.946 25.2705C134.738 26.0902 133.232 26.5 131.429 26.5Z"
                              fill="white"
                            />
                            <path
                              d="M150 16.0902H146.213V20.8716C146.213 21.8188 146.727 22.2923 147.755 22.2923H149.73V26.1721H147.403C144.392 26.1721 142.886 24.724 142.886 21.8279V16.0902H140.208V12.2377H142.886V9.01366L146.213 8.13934V12.2377H150V16.0902Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </Link>
                    </div>
                    <div className="oranlar">
                      <div className="oranlar-detay">
                        <span className="text-white">
                          Sizin için öngörülen meslekler
                        </span>
                        <div className="oran-div">
                          <span className="oran-text">{quizData.meslek1} </span>
                          <span className="oran-text">{quizData?.meslek1_yuzde}%</span>
                          <span
                            className="absolute-bg bg-green"
                            style={{ width: `${quizData?.meslek1_yuzde}%` }}
                          ></span>
                        </div>
                        <div className="oran-div">
                          <span className="oran-text">{quizData.meslek2} </span>
                          <span className="oran-text">{quizData?.meslek2_yuzde}%</span>
                          <span
                            className="absolute-bg bg-green-two"
                            style={{ width: `${quizData?.meslek2_yuzde}%` }}
                          ></span>
                        </div>
                        <div className="oran-div">
                          <span className="oran-text">{quizData.meslek3} </span>
                          <span className="oran-text">{quizData?.meslek3_yuzde}%</span>
                          <span
                            className="absolute-bg bg-green-three"
                            style={{ width: `${quizData?.meslek3_yuzde}%` }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="report-paper report-bg d-flex flex-column">
                    <div className="report-header report-header-bg d-flex justify-content-center">
                      <Link className="mx-auto" to="/">
                        <span>
                          <svg
                            width="150"
                            height="33"
                            viewBox="0 0 150 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16" cy="16.5" r="16" fill="#58CC02" />
                            <path
                              d="M22.6396 14.8586V17.3644H14.0312C14.0312 15.9806 15.1532 14.8586 16.537 14.8586H22.6396Z"
                              fill="white"
                            />
                            <path
                              d="M9.90421 10.0087V22.2143H13.5014C14.8852 22.2143 16.0072 23.3359 16.0072 24.7197C16.0072 23.3359 17.1292 22.2143 18.513 22.2143H24.6156V24.7197H18.513C17.1292 24.7197 16.0072 25.8417 16.0072 27.2258C16.0072 25.8417 14.8852 24.7197 13.5014 24.7197H7.39844V7.50293H13.5014C14.8852 7.50293 16.0072 8.62455 16.0072 10.0087C16.0072 8.62455 17.1292 7.50293 18.513 7.50293H24.6156V10.0087H18.513C17.1292 10.0087 16.0072 11.1307 16.0072 12.5145C16.0072 11.1307 14.8852 10.0087 13.5014 10.0087H9.90421Z"
                              fill="white"
                            />
                            <path
                              d="M56.8767 19.2869C56.8767 19.6876 56.8497 20.0519 56.7956 20.3798H45.3811C45.6335 21.1448 46.1294 21.755 46.8687 22.2104C47.6261 22.6658 48.5187 22.8934 49.5465 22.8934C51.1514 22.8934 52.3235 22.52 53.0629 21.7732H56.6874C56.1464 23.194 55.2267 24.3415 53.9284 25.2158C52.6301 26.0719 51.1424 26.5 49.4654 26.5C47.3376 26.5 45.5614 25.8078 44.1368 24.4235C42.7123 23.0209 42 21.2814 42 19.2049C42 17.1284 42.7033 15.398 44.1098 14.0137C45.5343 12.6111 47.3105 11.9098 49.4384 11.9098C51.5662 11.9098 53.3333 12.6111 54.7399 14.0137C56.1644 15.398 56.8767 17.1557 56.8767 19.2869ZM52.008 16.1995C51.2686 15.7441 50.4121 15.5164 49.4384 15.5164C48.4646 15.5164 47.6081 15.7441 46.8687 16.1995C46.1474 16.6548 45.6516 17.2559 45.3811 18.0027H53.5227C53.2702 17.2377 52.7653 16.6366 52.008 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M71.2522 6.5H74.5521V26.1721H71.2522V23.7678C70.0981 25.5893 68.4932 26.5 66.4375 26.5C64.5441 26.5 62.9573 25.8078 61.677 24.4235C60.4147 23.0209 59.7836 21.2814 59.7836 19.2049C59.7836 17.1284 60.4147 15.398 61.677 14.0137C62.9573 12.6111 64.5441 11.9098 66.4375 11.9098C68.4932 11.9098 70.0981 12.8206 71.2522 14.6421V6.5ZM64.3548 21.6093C65.1121 22.2468 66.0679 22.5656 67.2219 22.5656C68.376 22.5656 69.3317 22.2468 70.0891 21.6093C70.8645 20.9718 71.2522 20.1703 71.2522 19.2049C71.2522 18.2395 70.8645 17.4381 70.0891 16.8005C69.3317 16.163 68.376 15.8443 67.2219 15.8443C66.0679 15.8443 65.1121 16.163 64.3548 16.8005C63.5974 17.4381 63.2187 18.2395 63.2187 19.2049C63.2187 20.1703 63.5974 20.9718 64.3548 21.6093Z"
                              fill="white"
                            />
                            <path
                              d="M88.3952 12.2377H91.7222V26.1721H88.3952V23.7404C87.3494 25.5801 85.8256 26.5 83.824 26.5C82.3273 26.5 81.1102 26.0264 80.1725 25.0792C79.2528 24.1138 78.793 22.8479 78.793 21.2814V12.2377H82.0929V20.0792C82.0929 20.826 82.3724 21.4271 82.9314 21.8825C83.4904 22.3379 84.2298 22.5656 85.1494 22.5656C86.1232 22.5656 86.9076 22.3288 87.5026 21.8552C88.0977 21.3816 88.3952 20.7714 88.3952 20.0246V12.2377Z"
                              fill="white"
                            />
                            <path
                              d="M104.921 16.0902H101.134V20.8716C101.134 21.8188 101.648 22.2923 102.676 22.2923H104.65V26.1721H102.324C99.3128 26.1721 97.8071 24.724 97.8071 21.8279V16.0902H95.1293V12.2377H97.8071V9.01366L101.134 8.13934V12.2377H104.921V16.0902Z"
                              fill="white"
                            />
                            <path
                              d="M122.274 19.2869C122.274 19.6876 122.247 20.0519 122.193 20.3798H110.778C111.031 21.1448 111.527 21.755 112.266 22.2104C113.023 22.6658 113.916 22.8934 114.944 22.8934C116.549 22.8934 117.721 22.52 118.46 21.7732H122.085C121.544 23.194 120.624 24.3415 119.326 25.2158C118.027 26.0719 116.54 26.5 114.863 26.5C112.735 26.5 110.959 25.8078 109.534 24.4235C108.11 23.0209 107.397 21.2814 107.397 19.2049C107.397 17.1284 108.101 15.398 109.507 14.0137C110.932 12.6111 112.708 11.9098 114.836 11.9098C116.963 11.9098 118.731 12.6111 120.137 14.0137C121.562 15.398 122.274 17.1557 122.274 19.2869ZM117.405 16.1995C116.666 15.7441 115.809 15.5164 114.836 15.5164C113.862 15.5164 113.005 15.7441 112.266 16.1995C111.545 16.6548 111.049 17.2559 110.778 18.0027H118.92C118.668 17.2377 118.163 16.6366 117.405 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M131.429 26.5C129.608 26.5 128.093 26.0173 126.885 25.0519C125.677 24.0683 125.073 22.8752 125.073 21.4727H128.346C128.346 21.9281 128.634 22.3015 129.211 22.5929C129.806 22.8843 130.554 23.0301 131.456 23.0301C132.358 23.0301 133.079 22.9208 133.62 22.7022C134.179 22.4836 134.458 22.1922 134.458 21.8279C134.458 21.5 134.134 21.2359 133.485 21.0355C132.854 20.8352 131.889 20.653 130.591 20.4891C129.04 20.3069 127.768 19.8789 126.777 19.2049C125.785 18.531 125.289 17.5747 125.289 16.3361C125.289 14.9517 125.866 13.8679 127.02 13.0847C128.192 12.3015 129.644 11.9098 131.375 11.9098C133.142 11.9098 134.612 12.3743 135.784 13.3033C136.956 14.2322 137.542 15.3798 137.542 16.7459H134.215C134.215 16.3452 133.945 16.0173 133.404 15.7623C132.863 15.5073 132.186 15.3798 131.375 15.3798C130.545 15.3798 129.869 15.48 129.346 15.6803C128.841 15.8625 128.589 16.1266 128.589 16.4727C128.589 17.0556 129.878 17.5018 132.457 17.8115C135.991 18.2668 137.758 19.6239 137.758 21.8825C137.758 23.3215 137.154 24.4508 135.946 25.2705C134.738 26.0902 133.232 26.5 131.429 26.5Z"
                              fill="white"
                            />
                            <path
                              d="M150 16.0902H146.213V20.8716C146.213 21.8188 146.727 22.2923 147.755 22.2923H149.73V26.1721H147.403C144.392 26.1721 142.886 24.724 142.886 21.8279V16.0902H140.208V12.2377H142.886V9.01366L146.213 8.13934V12.2377H150V16.0902Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </Link>
                    </div>
                    <div className="col">
                      <div className="d-flex  page-padding  h-100">
                        <JobReportText
                          class="article-page"
                          initialContent={job.yazi}
                        ></JobReportText>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="report-paper report-bg d-flex flex-column">
                    <div className="report-header report-header-bg d-flex justify-content-center">
                      <Link className="mx-auto" to="/">
                        <span>
                          <svg
                            width="150"
                            height="33"
                            viewBox="0 0 150 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16" cy="16.5" r="16" fill="#58CC02" />
                            <path
                              d="M22.6396 14.8586V17.3644H14.0312C14.0312 15.9806 15.1532 14.8586 16.537 14.8586H22.6396Z"
                              fill="white"
                            />
                            <path
                              d="M9.90421 10.0087V22.2143H13.5014C14.8852 22.2143 16.0072 23.3359 16.0072 24.7197C16.0072 23.3359 17.1292 22.2143 18.513 22.2143H24.6156V24.7197H18.513C17.1292 24.7197 16.0072 25.8417 16.0072 27.2258C16.0072 25.8417 14.8852 24.7197 13.5014 24.7197H7.39844V7.50293H13.5014C14.8852 7.50293 16.0072 8.62455 16.0072 10.0087C16.0072 8.62455 17.1292 7.50293 18.513 7.50293H24.6156V10.0087H18.513C17.1292 10.0087 16.0072 11.1307 16.0072 12.5145C16.0072 11.1307 14.8852 10.0087 13.5014 10.0087H9.90421Z"
                              fill="white"
                            />
                            <path
                              d="M56.8767 19.2869C56.8767 19.6876 56.8497 20.0519 56.7956 20.3798H45.3811C45.6335 21.1448 46.1294 21.755 46.8687 22.2104C47.6261 22.6658 48.5187 22.8934 49.5465 22.8934C51.1514 22.8934 52.3235 22.52 53.0629 21.7732H56.6874C56.1464 23.194 55.2267 24.3415 53.9284 25.2158C52.6301 26.0719 51.1424 26.5 49.4654 26.5C47.3376 26.5 45.5614 25.8078 44.1368 24.4235C42.7123 23.0209 42 21.2814 42 19.2049C42 17.1284 42.7033 15.398 44.1098 14.0137C45.5343 12.6111 47.3105 11.9098 49.4384 11.9098C51.5662 11.9098 53.3333 12.6111 54.7399 14.0137C56.1644 15.398 56.8767 17.1557 56.8767 19.2869ZM52.008 16.1995C51.2686 15.7441 50.4121 15.5164 49.4384 15.5164C48.4646 15.5164 47.6081 15.7441 46.8687 16.1995C46.1474 16.6548 45.6516 17.2559 45.3811 18.0027H53.5227C53.2702 17.2377 52.7653 16.6366 52.008 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M71.2522 6.5H74.5521V26.1721H71.2522V23.7678C70.0981 25.5893 68.4932 26.5 66.4375 26.5C64.5441 26.5 62.9573 25.8078 61.677 24.4235C60.4147 23.0209 59.7836 21.2814 59.7836 19.2049C59.7836 17.1284 60.4147 15.398 61.677 14.0137C62.9573 12.6111 64.5441 11.9098 66.4375 11.9098C68.4932 11.9098 70.0981 12.8206 71.2522 14.6421V6.5ZM64.3548 21.6093C65.1121 22.2468 66.0679 22.5656 67.2219 22.5656C68.376 22.5656 69.3317 22.2468 70.0891 21.6093C70.8645 20.9718 71.2522 20.1703 71.2522 19.2049C71.2522 18.2395 70.8645 17.4381 70.0891 16.8005C69.3317 16.163 68.376 15.8443 67.2219 15.8443C66.0679 15.8443 65.1121 16.163 64.3548 16.8005C63.5974 17.4381 63.2187 18.2395 63.2187 19.2049C63.2187 20.1703 63.5974 20.9718 64.3548 21.6093Z"
                              fill="white"
                            />
                            <path
                              d="M88.3952 12.2377H91.7222V26.1721H88.3952V23.7404C87.3494 25.5801 85.8256 26.5 83.824 26.5C82.3273 26.5 81.1102 26.0264 80.1725 25.0792C79.2528 24.1138 78.793 22.8479 78.793 21.2814V12.2377H82.0929V20.0792C82.0929 20.826 82.3724 21.4271 82.9314 21.8825C83.4904 22.3379 84.2298 22.5656 85.1494 22.5656C86.1232 22.5656 86.9076 22.3288 87.5026 21.8552C88.0977 21.3816 88.3952 20.7714 88.3952 20.0246V12.2377Z"
                              fill="white"
                            />
                            <path
                              d="M104.921 16.0902H101.134V20.8716C101.134 21.8188 101.648 22.2923 102.676 22.2923H104.65V26.1721H102.324C99.3128 26.1721 97.8071 24.724 97.8071 21.8279V16.0902H95.1293V12.2377H97.8071V9.01366L101.134 8.13934V12.2377H104.921V16.0902Z"
                              fill="white"
                            />
                            <path
                              d="M122.274 19.2869C122.274 19.6876 122.247 20.0519 122.193 20.3798H110.778C111.031 21.1448 111.527 21.755 112.266 22.2104C113.023 22.6658 113.916 22.8934 114.944 22.8934C116.549 22.8934 117.721 22.52 118.46 21.7732H122.085C121.544 23.194 120.624 24.3415 119.326 25.2158C118.027 26.0719 116.54 26.5 114.863 26.5C112.735 26.5 110.959 25.8078 109.534 24.4235C108.11 23.0209 107.397 21.2814 107.397 19.2049C107.397 17.1284 108.101 15.398 109.507 14.0137C110.932 12.6111 112.708 11.9098 114.836 11.9098C116.963 11.9098 118.731 12.6111 120.137 14.0137C121.562 15.398 122.274 17.1557 122.274 19.2869ZM117.405 16.1995C116.666 15.7441 115.809 15.5164 114.836 15.5164C113.862 15.5164 113.005 15.7441 112.266 16.1995C111.545 16.6548 111.049 17.2559 110.778 18.0027H118.92C118.668 17.2377 118.163 16.6366 117.405 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M131.429 26.5C129.608 26.5 128.093 26.0173 126.885 25.0519C125.677 24.0683 125.073 22.8752 125.073 21.4727H128.346C128.346 21.9281 128.634 22.3015 129.211 22.5929C129.806 22.8843 130.554 23.0301 131.456 23.0301C132.358 23.0301 133.079 22.9208 133.62 22.7022C134.179 22.4836 134.458 22.1922 134.458 21.8279C134.458 21.5 134.134 21.2359 133.485 21.0355C132.854 20.8352 131.889 20.653 130.591 20.4891C129.04 20.3069 127.768 19.8789 126.777 19.2049C125.785 18.531 125.289 17.5747 125.289 16.3361C125.289 14.9517 125.866 13.8679 127.02 13.0847C128.192 12.3015 129.644 11.9098 131.375 11.9098C133.142 11.9098 134.612 12.3743 135.784 13.3033C136.956 14.2322 137.542 15.3798 137.542 16.7459H134.215C134.215 16.3452 133.945 16.0173 133.404 15.7623C132.863 15.5073 132.186 15.3798 131.375 15.3798C130.545 15.3798 129.869 15.48 129.346 15.6803C128.841 15.8625 128.589 16.1266 128.589 16.4727C128.589 17.0556 129.878 17.5018 132.457 17.8115C135.991 18.2668 137.758 19.6239 137.758 21.8825C137.758 23.3215 137.154 24.4508 135.946 25.2705C134.738 26.0902 133.232 26.5 131.429 26.5Z"
                              fill="white"
                            />
                            <path
                              d="M150 16.0902H146.213V20.8716C146.213 21.8188 146.727 22.2923 147.755 22.2923H149.73V26.1721H147.403C144.392 26.1721 142.886 24.724 142.886 21.8279V16.0902H140.208V12.2377H142.886V9.01366L146.213 8.13934V12.2377H150V16.0902Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </Link>
                    </div>
                    <div className="col">
                      <div className="d-flex  page-padding  h-100">
                        <JobReportText
                          class="article-page"
                          initialContent={job.oneriler}
                        ></JobReportText>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="report-paper report-bg d-flex flex-column">
                    <div className="report-header report-header-bg d-flex justify-content-center">
                      <Link className="mx-auto" to="/">
                        <span>
                          <svg
                            width="150"
                            height="33"
                            viewBox="0 0 150 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16" cy="16.5" r="16" fill="#58CC02" />
                            <path
                              d="M22.6396 14.8586V17.3644H14.0312C14.0312 15.9806 15.1532 14.8586 16.537 14.8586H22.6396Z"
                              fill="white"
                            />
                            <path
                              d="M9.90421 10.0087V22.2143H13.5014C14.8852 22.2143 16.0072 23.3359 16.0072 24.7197C16.0072 23.3359 17.1292 22.2143 18.513 22.2143H24.6156V24.7197H18.513C17.1292 24.7197 16.0072 25.8417 16.0072 27.2258C16.0072 25.8417 14.8852 24.7197 13.5014 24.7197H7.39844V7.50293H13.5014C14.8852 7.50293 16.0072 8.62455 16.0072 10.0087C16.0072 8.62455 17.1292 7.50293 18.513 7.50293H24.6156V10.0087H18.513C17.1292 10.0087 16.0072 11.1307 16.0072 12.5145C16.0072 11.1307 14.8852 10.0087 13.5014 10.0087H9.90421Z"
                              fill="white"
                            />
                            <path
                              d="M56.8767 19.2869C56.8767 19.6876 56.8497 20.0519 56.7956 20.3798H45.3811C45.6335 21.1448 46.1294 21.755 46.8687 22.2104C47.6261 22.6658 48.5187 22.8934 49.5465 22.8934C51.1514 22.8934 52.3235 22.52 53.0629 21.7732H56.6874C56.1464 23.194 55.2267 24.3415 53.9284 25.2158C52.6301 26.0719 51.1424 26.5 49.4654 26.5C47.3376 26.5 45.5614 25.8078 44.1368 24.4235C42.7123 23.0209 42 21.2814 42 19.2049C42 17.1284 42.7033 15.398 44.1098 14.0137C45.5343 12.6111 47.3105 11.9098 49.4384 11.9098C51.5662 11.9098 53.3333 12.6111 54.7399 14.0137C56.1644 15.398 56.8767 17.1557 56.8767 19.2869ZM52.008 16.1995C51.2686 15.7441 50.4121 15.5164 49.4384 15.5164C48.4646 15.5164 47.6081 15.7441 46.8687 16.1995C46.1474 16.6548 45.6516 17.2559 45.3811 18.0027H53.5227C53.2702 17.2377 52.7653 16.6366 52.008 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M71.2522 6.5H74.5521V26.1721H71.2522V23.7678C70.0981 25.5893 68.4932 26.5 66.4375 26.5C64.5441 26.5 62.9573 25.8078 61.677 24.4235C60.4147 23.0209 59.7836 21.2814 59.7836 19.2049C59.7836 17.1284 60.4147 15.398 61.677 14.0137C62.9573 12.6111 64.5441 11.9098 66.4375 11.9098C68.4932 11.9098 70.0981 12.8206 71.2522 14.6421V6.5ZM64.3548 21.6093C65.1121 22.2468 66.0679 22.5656 67.2219 22.5656C68.376 22.5656 69.3317 22.2468 70.0891 21.6093C70.8645 20.9718 71.2522 20.1703 71.2522 19.2049C71.2522 18.2395 70.8645 17.4381 70.0891 16.8005C69.3317 16.163 68.376 15.8443 67.2219 15.8443C66.0679 15.8443 65.1121 16.163 64.3548 16.8005C63.5974 17.4381 63.2187 18.2395 63.2187 19.2049C63.2187 20.1703 63.5974 20.9718 64.3548 21.6093Z"
                              fill="white"
                            />
                            <path
                              d="M88.3952 12.2377H91.7222V26.1721H88.3952V23.7404C87.3494 25.5801 85.8256 26.5 83.824 26.5C82.3273 26.5 81.1102 26.0264 80.1725 25.0792C79.2528 24.1138 78.793 22.8479 78.793 21.2814V12.2377H82.0929V20.0792C82.0929 20.826 82.3724 21.4271 82.9314 21.8825C83.4904 22.3379 84.2298 22.5656 85.1494 22.5656C86.1232 22.5656 86.9076 22.3288 87.5026 21.8552C88.0977 21.3816 88.3952 20.7714 88.3952 20.0246V12.2377Z"
                              fill="white"
                            />
                            <path
                              d="M104.921 16.0902H101.134V20.8716C101.134 21.8188 101.648 22.2923 102.676 22.2923H104.65V26.1721H102.324C99.3128 26.1721 97.8071 24.724 97.8071 21.8279V16.0902H95.1293V12.2377H97.8071V9.01366L101.134 8.13934V12.2377H104.921V16.0902Z"
                              fill="white"
                            />
                            <path
                              d="M122.274 19.2869C122.274 19.6876 122.247 20.0519 122.193 20.3798H110.778C111.031 21.1448 111.527 21.755 112.266 22.2104C113.023 22.6658 113.916 22.8934 114.944 22.8934C116.549 22.8934 117.721 22.52 118.46 21.7732H122.085C121.544 23.194 120.624 24.3415 119.326 25.2158C118.027 26.0719 116.54 26.5 114.863 26.5C112.735 26.5 110.959 25.8078 109.534 24.4235C108.11 23.0209 107.397 21.2814 107.397 19.2049C107.397 17.1284 108.101 15.398 109.507 14.0137C110.932 12.6111 112.708 11.9098 114.836 11.9098C116.963 11.9098 118.731 12.6111 120.137 14.0137C121.562 15.398 122.274 17.1557 122.274 19.2869ZM117.405 16.1995C116.666 15.7441 115.809 15.5164 114.836 15.5164C113.862 15.5164 113.005 15.7441 112.266 16.1995C111.545 16.6548 111.049 17.2559 110.778 18.0027H118.92C118.668 17.2377 118.163 16.6366 117.405 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M131.429 26.5C129.608 26.5 128.093 26.0173 126.885 25.0519C125.677 24.0683 125.073 22.8752 125.073 21.4727H128.346C128.346 21.9281 128.634 22.3015 129.211 22.5929C129.806 22.8843 130.554 23.0301 131.456 23.0301C132.358 23.0301 133.079 22.9208 133.62 22.7022C134.179 22.4836 134.458 22.1922 134.458 21.8279C134.458 21.5 134.134 21.2359 133.485 21.0355C132.854 20.8352 131.889 20.653 130.591 20.4891C129.04 20.3069 127.768 19.8789 126.777 19.2049C125.785 18.531 125.289 17.5747 125.289 16.3361C125.289 14.9517 125.866 13.8679 127.02 13.0847C128.192 12.3015 129.644 11.9098 131.375 11.9098C133.142 11.9098 134.612 12.3743 135.784 13.3033C136.956 14.2322 137.542 15.3798 137.542 16.7459H134.215C134.215 16.3452 133.945 16.0173 133.404 15.7623C132.863 15.5073 132.186 15.3798 131.375 15.3798C130.545 15.3798 129.869 15.48 129.346 15.6803C128.841 15.8625 128.589 16.1266 128.589 16.4727C128.589 17.0556 129.878 17.5018 132.457 17.8115C135.991 18.2668 137.758 19.6239 137.758 21.8825C137.758 23.3215 137.154 24.4508 135.946 25.2705C134.738 26.0902 133.232 26.5 131.429 26.5Z"
                              fill="white"
                            />
                            <path
                              d="M150 16.0902H146.213V20.8716C146.213 21.8188 146.727 22.2923 147.755 22.2923H149.73V26.1721H147.403C144.392 26.1721 142.886 24.724 142.886 21.8279V16.0902H140.208V12.2377H142.886V9.01366L146.213 8.13934V12.2377H150V16.0902Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </Link>
                    </div>
                    <div className="col">
                      <div className="  page-padding  h-100">
                        <JobReportText
                          class="article-page"
                          initialContent={job.oneriler_madde}
                        ></JobReportText>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="report-paper report-bg d-flex flex-column">
                    <div className="report-header report-header-bg d-flex justify-content-center">
                      <Link className="mx-auto" to="/">
                        <span>
                          <svg
                            width="150"
                            height="33"
                            viewBox="0 0 150 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16" cy="16.5" r="16" fill="#58CC02" />
                            <path
                              d="M22.6396 14.8586V17.3644H14.0312C14.0312 15.9806 15.1532 14.8586 16.537 14.8586H22.6396Z"
                              fill="white"
                            />
                            <path
                              d="M9.90421 10.0087V22.2143H13.5014C14.8852 22.2143 16.0072 23.3359 16.0072 24.7197C16.0072 23.3359 17.1292 22.2143 18.513 22.2143H24.6156V24.7197H18.513C17.1292 24.7197 16.0072 25.8417 16.0072 27.2258C16.0072 25.8417 14.8852 24.7197 13.5014 24.7197H7.39844V7.50293H13.5014C14.8852 7.50293 16.0072 8.62455 16.0072 10.0087C16.0072 8.62455 17.1292 7.50293 18.513 7.50293H24.6156V10.0087H18.513C17.1292 10.0087 16.0072 11.1307 16.0072 12.5145C16.0072 11.1307 14.8852 10.0087 13.5014 10.0087H9.90421Z"
                              fill="white"
                            />
                            <path
                              d="M56.8767 19.2869C56.8767 19.6876 56.8497 20.0519 56.7956 20.3798H45.3811C45.6335 21.1448 46.1294 21.755 46.8687 22.2104C47.6261 22.6658 48.5187 22.8934 49.5465 22.8934C51.1514 22.8934 52.3235 22.52 53.0629 21.7732H56.6874C56.1464 23.194 55.2267 24.3415 53.9284 25.2158C52.6301 26.0719 51.1424 26.5 49.4654 26.5C47.3376 26.5 45.5614 25.8078 44.1368 24.4235C42.7123 23.0209 42 21.2814 42 19.2049C42 17.1284 42.7033 15.398 44.1098 14.0137C45.5343 12.6111 47.3105 11.9098 49.4384 11.9098C51.5662 11.9098 53.3333 12.6111 54.7399 14.0137C56.1644 15.398 56.8767 17.1557 56.8767 19.2869ZM52.008 16.1995C51.2686 15.7441 50.4121 15.5164 49.4384 15.5164C48.4646 15.5164 47.6081 15.7441 46.8687 16.1995C46.1474 16.6548 45.6516 17.2559 45.3811 18.0027H53.5227C53.2702 17.2377 52.7653 16.6366 52.008 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M71.2522 6.5H74.5521V26.1721H71.2522V23.7678C70.0981 25.5893 68.4932 26.5 66.4375 26.5C64.5441 26.5 62.9573 25.8078 61.677 24.4235C60.4147 23.0209 59.7836 21.2814 59.7836 19.2049C59.7836 17.1284 60.4147 15.398 61.677 14.0137C62.9573 12.6111 64.5441 11.9098 66.4375 11.9098C68.4932 11.9098 70.0981 12.8206 71.2522 14.6421V6.5ZM64.3548 21.6093C65.1121 22.2468 66.0679 22.5656 67.2219 22.5656C68.376 22.5656 69.3317 22.2468 70.0891 21.6093C70.8645 20.9718 71.2522 20.1703 71.2522 19.2049C71.2522 18.2395 70.8645 17.4381 70.0891 16.8005C69.3317 16.163 68.376 15.8443 67.2219 15.8443C66.0679 15.8443 65.1121 16.163 64.3548 16.8005C63.5974 17.4381 63.2187 18.2395 63.2187 19.2049C63.2187 20.1703 63.5974 20.9718 64.3548 21.6093Z"
                              fill="white"
                            />
                            <path
                              d="M88.3952 12.2377H91.7222V26.1721H88.3952V23.7404C87.3494 25.5801 85.8256 26.5 83.824 26.5C82.3273 26.5 81.1102 26.0264 80.1725 25.0792C79.2528 24.1138 78.793 22.8479 78.793 21.2814V12.2377H82.0929V20.0792C82.0929 20.826 82.3724 21.4271 82.9314 21.8825C83.4904 22.3379 84.2298 22.5656 85.1494 22.5656C86.1232 22.5656 86.9076 22.3288 87.5026 21.8552C88.0977 21.3816 88.3952 20.7714 88.3952 20.0246V12.2377Z"
                              fill="white"
                            />
                            <path
                              d="M104.921 16.0902H101.134V20.8716C101.134 21.8188 101.648 22.2923 102.676 22.2923H104.65V26.1721H102.324C99.3128 26.1721 97.8071 24.724 97.8071 21.8279V16.0902H95.1293V12.2377H97.8071V9.01366L101.134 8.13934V12.2377H104.921V16.0902Z"
                              fill="white"
                            />
                            <path
                              d="M122.274 19.2869C122.274 19.6876 122.247 20.0519 122.193 20.3798H110.778C111.031 21.1448 111.527 21.755 112.266 22.2104C113.023 22.6658 113.916 22.8934 114.944 22.8934C116.549 22.8934 117.721 22.52 118.46 21.7732H122.085C121.544 23.194 120.624 24.3415 119.326 25.2158C118.027 26.0719 116.54 26.5 114.863 26.5C112.735 26.5 110.959 25.8078 109.534 24.4235C108.11 23.0209 107.397 21.2814 107.397 19.2049C107.397 17.1284 108.101 15.398 109.507 14.0137C110.932 12.6111 112.708 11.9098 114.836 11.9098C116.963 11.9098 118.731 12.6111 120.137 14.0137C121.562 15.398 122.274 17.1557 122.274 19.2869ZM117.405 16.1995C116.666 15.7441 115.809 15.5164 114.836 15.5164C113.862 15.5164 113.005 15.7441 112.266 16.1995C111.545 16.6548 111.049 17.2559 110.778 18.0027H118.92C118.668 17.2377 118.163 16.6366 117.405 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M131.429 26.5C129.608 26.5 128.093 26.0173 126.885 25.0519C125.677 24.0683 125.073 22.8752 125.073 21.4727H128.346C128.346 21.9281 128.634 22.3015 129.211 22.5929C129.806 22.8843 130.554 23.0301 131.456 23.0301C132.358 23.0301 133.079 22.9208 133.62 22.7022C134.179 22.4836 134.458 22.1922 134.458 21.8279C134.458 21.5 134.134 21.2359 133.485 21.0355C132.854 20.8352 131.889 20.653 130.591 20.4891C129.04 20.3069 127.768 19.8789 126.777 19.2049C125.785 18.531 125.289 17.5747 125.289 16.3361C125.289 14.9517 125.866 13.8679 127.02 13.0847C128.192 12.3015 129.644 11.9098 131.375 11.9098C133.142 11.9098 134.612 12.3743 135.784 13.3033C136.956 14.2322 137.542 15.3798 137.542 16.7459H134.215C134.215 16.3452 133.945 16.0173 133.404 15.7623C132.863 15.5073 132.186 15.3798 131.375 15.3798C130.545 15.3798 129.869 15.48 129.346 15.6803C128.841 15.8625 128.589 16.1266 128.589 16.4727C128.589 17.0556 129.878 17.5018 132.457 17.8115C135.991 18.2668 137.758 19.6239 137.758 21.8825C137.758 23.3215 137.154 24.4508 135.946 25.2705C134.738 26.0902 133.232 26.5 131.429 26.5Z"
                              fill="white"
                            />
                            <path
                              d="M150 16.0902H146.213V20.8716C146.213 21.8188 146.727 22.2923 147.755 22.2923H149.73V26.1721H147.403C144.392 26.1721 142.886 24.724 142.886 21.8279V16.0902H140.208V12.2377H142.886V9.01366L146.213 8.13934V12.2377H150V16.0902Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </Link>
                    </div>
                    <div className="col">
                      <div className="  page-padding  h-100">
                        <h3 className="page-header-title mb-4">
                          {" "}
                          Uzmanlarımızın Önerileri
                        </h3>

                        <JobReportText
                          class="article-page"
                          initialContent={job.aile_onerileri}
                        ></JobReportText>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="report-paper report-bg d-flex flex-column">
                    <div className="report-header report-header-bg d-flex justify-content-center">
                      <Link className="mx-auto" to="/">
                        <span>
                          <svg
                            width="150"
                            height="33"
                            viewBox="0 0 150 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16" cy="16.5" r="16" fill="#58CC02" />
                            <path
                              d="M22.6396 14.8586V17.3644H14.0312C14.0312 15.9806 15.1532 14.8586 16.537 14.8586H22.6396Z"
                              fill="white"
                            />
                            <path
                              d="M9.90421 10.0087V22.2143H13.5014C14.8852 22.2143 16.0072 23.3359 16.0072 24.7197C16.0072 23.3359 17.1292 22.2143 18.513 22.2143H24.6156V24.7197H18.513C17.1292 24.7197 16.0072 25.8417 16.0072 27.2258C16.0072 25.8417 14.8852 24.7197 13.5014 24.7197H7.39844V7.50293H13.5014C14.8852 7.50293 16.0072 8.62455 16.0072 10.0087C16.0072 8.62455 17.1292 7.50293 18.513 7.50293H24.6156V10.0087H18.513C17.1292 10.0087 16.0072 11.1307 16.0072 12.5145C16.0072 11.1307 14.8852 10.0087 13.5014 10.0087H9.90421Z"
                              fill="white"
                            />
                            <path
                              d="M56.8767 19.2869C56.8767 19.6876 56.8497 20.0519 56.7956 20.3798H45.3811C45.6335 21.1448 46.1294 21.755 46.8687 22.2104C47.6261 22.6658 48.5187 22.8934 49.5465 22.8934C51.1514 22.8934 52.3235 22.52 53.0629 21.7732H56.6874C56.1464 23.194 55.2267 24.3415 53.9284 25.2158C52.6301 26.0719 51.1424 26.5 49.4654 26.5C47.3376 26.5 45.5614 25.8078 44.1368 24.4235C42.7123 23.0209 42 21.2814 42 19.2049C42 17.1284 42.7033 15.398 44.1098 14.0137C45.5343 12.6111 47.3105 11.9098 49.4384 11.9098C51.5662 11.9098 53.3333 12.6111 54.7399 14.0137C56.1644 15.398 56.8767 17.1557 56.8767 19.2869ZM52.008 16.1995C51.2686 15.7441 50.4121 15.5164 49.4384 15.5164C48.4646 15.5164 47.6081 15.7441 46.8687 16.1995C46.1474 16.6548 45.6516 17.2559 45.3811 18.0027H53.5227C53.2702 17.2377 52.7653 16.6366 52.008 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M71.2522 6.5H74.5521V26.1721H71.2522V23.7678C70.0981 25.5893 68.4932 26.5 66.4375 26.5C64.5441 26.5 62.9573 25.8078 61.677 24.4235C60.4147 23.0209 59.7836 21.2814 59.7836 19.2049C59.7836 17.1284 60.4147 15.398 61.677 14.0137C62.9573 12.6111 64.5441 11.9098 66.4375 11.9098C68.4932 11.9098 70.0981 12.8206 71.2522 14.6421V6.5ZM64.3548 21.6093C65.1121 22.2468 66.0679 22.5656 67.2219 22.5656C68.376 22.5656 69.3317 22.2468 70.0891 21.6093C70.8645 20.9718 71.2522 20.1703 71.2522 19.2049C71.2522 18.2395 70.8645 17.4381 70.0891 16.8005C69.3317 16.163 68.376 15.8443 67.2219 15.8443C66.0679 15.8443 65.1121 16.163 64.3548 16.8005C63.5974 17.4381 63.2187 18.2395 63.2187 19.2049C63.2187 20.1703 63.5974 20.9718 64.3548 21.6093Z"
                              fill="white"
                            />
                            <path
                              d="M88.3952 12.2377H91.7222V26.1721H88.3952V23.7404C87.3494 25.5801 85.8256 26.5 83.824 26.5C82.3273 26.5 81.1102 26.0264 80.1725 25.0792C79.2528 24.1138 78.793 22.8479 78.793 21.2814V12.2377H82.0929V20.0792C82.0929 20.826 82.3724 21.4271 82.9314 21.8825C83.4904 22.3379 84.2298 22.5656 85.1494 22.5656C86.1232 22.5656 86.9076 22.3288 87.5026 21.8552C88.0977 21.3816 88.3952 20.7714 88.3952 20.0246V12.2377Z"
                              fill="white"
                            />
                            <path
                              d="M104.921 16.0902H101.134V20.8716C101.134 21.8188 101.648 22.2923 102.676 22.2923H104.65V26.1721H102.324C99.3128 26.1721 97.8071 24.724 97.8071 21.8279V16.0902H95.1293V12.2377H97.8071V9.01366L101.134 8.13934V12.2377H104.921V16.0902Z"
                              fill="white"
                            />
                            <path
                              d="M122.274 19.2869C122.274 19.6876 122.247 20.0519 122.193 20.3798H110.778C111.031 21.1448 111.527 21.755 112.266 22.2104C113.023 22.6658 113.916 22.8934 114.944 22.8934C116.549 22.8934 117.721 22.52 118.46 21.7732H122.085C121.544 23.194 120.624 24.3415 119.326 25.2158C118.027 26.0719 116.54 26.5 114.863 26.5C112.735 26.5 110.959 25.8078 109.534 24.4235C108.11 23.0209 107.397 21.2814 107.397 19.2049C107.397 17.1284 108.101 15.398 109.507 14.0137C110.932 12.6111 112.708 11.9098 114.836 11.9098C116.963 11.9098 118.731 12.6111 120.137 14.0137C121.562 15.398 122.274 17.1557 122.274 19.2869ZM117.405 16.1995C116.666 15.7441 115.809 15.5164 114.836 15.5164C113.862 15.5164 113.005 15.7441 112.266 16.1995C111.545 16.6548 111.049 17.2559 110.778 18.0027H118.92C118.668 17.2377 118.163 16.6366 117.405 16.1995Z"
                              fill="white"
                            />
                            <path
                              d="M131.429 26.5C129.608 26.5 128.093 26.0173 126.885 25.0519C125.677 24.0683 125.073 22.8752 125.073 21.4727H128.346C128.346 21.9281 128.634 22.3015 129.211 22.5929C129.806 22.8843 130.554 23.0301 131.456 23.0301C132.358 23.0301 133.079 22.9208 133.62 22.7022C134.179 22.4836 134.458 22.1922 134.458 21.8279C134.458 21.5 134.134 21.2359 133.485 21.0355C132.854 20.8352 131.889 20.653 130.591 20.4891C129.04 20.3069 127.768 19.8789 126.777 19.2049C125.785 18.531 125.289 17.5747 125.289 16.3361C125.289 14.9517 125.866 13.8679 127.02 13.0847C128.192 12.3015 129.644 11.9098 131.375 11.9098C133.142 11.9098 134.612 12.3743 135.784 13.3033C136.956 14.2322 137.542 15.3798 137.542 16.7459H134.215C134.215 16.3452 133.945 16.0173 133.404 15.7623C132.863 15.5073 132.186 15.3798 131.375 15.3798C130.545 15.3798 129.869 15.48 129.346 15.6803C128.841 15.8625 128.589 16.1266 128.589 16.4727C128.589 17.0556 129.878 17.5018 132.457 17.8115C135.991 18.2668 137.758 19.6239 137.758 21.8825C137.758 23.3215 137.154 24.4508 135.946 25.2705C134.738 26.0902 133.232 26.5 131.429 26.5Z"
                              fill="white"
                            />
                            <path
                              d="M150 16.0902H146.213V20.8716C146.213 21.8188 146.727 22.2923 147.755 22.2923H149.73V26.1721H147.403C144.392 26.1721 142.886 24.724 142.886 21.8279V16.0902H140.208V12.2377H142.886V9.01366L146.213 8.13934V12.2377H150V16.0902Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </Link>
                    </div>
                    <div className="col">
                      <div className="  page-padding  h-100">
                        <h3 className="page-header-title mb-4">
                          Bu Alanda Başarı Sağlamış Bazı Kişiler
                        </h3>
                        <div className="fotolar">
                         {unluler.length > 0 && unluler?.slice(0,4).map((unlu) => (
                           <div class="foto">
                           <div class="foto-div">
                             <img
                               src={"https://unluler.eduvery.xyz/" + unlu.photo}
                               alt=""
                             />
                           </div>
                           <span class="text-white fw-medium">
                             {unlu.name}
                           </span>
                         </div>
                         ))}
                          
                        </div>
                        <p>Bu alanda başarı göstermiş birçok ünlü isim, genç yeteneklerin ilham alabileceği rol modeller olarak karşımıza çıkıyor. Ancak, bu başarıların ardında sadece yetenek değil, aynı zamanda disiplin, öğrenmeye açıklık, kararlılık ve sürekli gelişim gibi önemli faktörler de bulunmaktadır.
</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </Container>
        </div>
      )}
    </Fragment>
  );
};

export default JobReport;
