/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext, useState } from "react";
import { questionsData } from "../data/QuestionsData";
import { useSelector } from "react-redux";
import { useApi } from "../hooks/useApi";
import { useLoaderContext } from "./LoaderContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const QuestionsContext = createContext();

export const QuestionsContextProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isVisible, setIsVisible] = useState(0);
  const [newQuestion, setNewQuestion] = useState({
    question_data: [],
    answer_data: [],
  });
  const [photoQuestion,setPhotoQuestion] = useState(false)
  const { quiz_id } = useSelector((state) => state.user);
  const { showLoader, hideLoader } = useLoaderContext();
  const navigate = useNavigate()
  const nextQuestionRequest = (data) => {
    const formData = new FormData();
    formData.append("quiz_id", quiz_id);
    for (const key in data) {
      formData.append(key, data[key]);
    }
    showLoader();
    setPhotoQuestion(false)
    useApi()
      .post("quiz/answer", formData)
      .then((res) => {
        if (res.data.status) {

          if(res.data.finish){
            navigate("/finish")
            hideLoader()
          }else{
            res.data.answer_data.map((answer) => {
              if(answer.photo !== null){
                setPhotoQuestion(true)
              }
            })
            setNewQuestion(res.data);
            setActiveStep(activeStep + 1);
            hideLoader();
            window.scrollTo(0, 0);
          }
          
        }else{
          hideLoader()
          toast.error("Rapor oluşturulurken bir hata oluştu, daha sonra profil sayfasınız kontrol ediniz.")
        }
      }).catch((err) => {
        console.error(err)
        hideLoader()
      });
  };
  const values = {
    activeStep,
    setActiveStep,
    isVisible,
    setIsVisible,
    questionsData,
    newQuestion,
    setNewQuestion,
    nextQuestionRequest,
    photoQuestion,
    setPhotoQuestion
  };
  return (
    <QuestionsContext.Provider value={values}>
      {children}
    </QuestionsContext.Provider>
  );
};

export const useQuestionContext = () => useContext(QuestionsContext);
