import React, { useEffect, useState } from "react";
import "./header.scss";
import {
  Button,
  Container,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { useSelector } from "react-redux";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [userLogin, setUserLogin] = useState(false);
  const {userData} = useSelector(state => state.user)
  useEffect(()=>{
    if(userData?.id){
      setUserLogin(true)
    }
  },[userData])
  const handleNavLinkClick = () => {
    setOpen(false); 
  };

  return (
    <header>
      <Container>
        <div className="d-flex justify-content-between align-items-center">
          <Link to={"/"} >
            <img src="/images/logo.svg" alt="Eduvery Logo" />
          </Link>
          <Button
            color="transparent"
            className="d-lg-none d-block"
            onClick={() => setOpen(!open)}
          >
            <IoMenu size={30} color="#FFF" />
          </Button>
          <div className="d-lg-flex d-none justify-content-between header-navs">
            <NavLink to={"/edutest"} className={"nav-link"}>
              Edutest
            </NavLink>
            <NavLink to={"/about"} className={"nav-link"}>
              Hakkımızda
            </NavLink>
            <NavLink to={"/contact"} className={"nav-link"}>
              Bize Ulaşın
            </NavLink>
            {userLogin && <NavLink to={"profile"} className={"nav-link"}>
              Hesabım
            </NavLink>}
          </div>
          <div className="d-lg-flex d-none  align-items-center gap-4">
            {!userLogin ? (
              <>
                <Link to={"register"} className="btn btn-lg btn-outline-white">
                  Kayıt Ol
                </Link>
                <Link to={"login"} className="btn btn-lg btn-white">
                  Giriş Yap
                </Link>{" "}
              </>
            ) : (
              <Link to={"before-questions"} className="btn btn-lg btn-white btn-sm">
                <div className="d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="39"
                    viewBox="0 0 40 39"
                    fill="none"
                  >
                    <circle cx="20.2578" cy="19.5" r="19.5" fill="#58CC02" />
                    <path
                      d="M29.5938 17.7678C30.9271 18.5376 30.9271 20.4621 29.5937 21.2319L17.0886 28.4517C15.7553 29.2215 14.0886 28.2592 14.0886 26.7196L14.0886 12.28C14.0886 10.7404 15.7553 9.77815 17.0886 10.5479L29.5938 17.7678Z"
                      fill="white"
                    />
                  </svg>
                  Şimdi Başla
                </div>
              </Link>
            )}{" "}
          </div>
        </div>
      </Container>
      <Offcanvas
        className="mobile-menu"
        direction="end"
        isOpen={open}
        toggle={() => setOpen(!open)}
      >
        <OffcanvasHeader toggle={() => setOpen(!open)}>
          <Link to={"/"} onClick={handleNavLinkClick}>
            <img src="/images/logo.svg" alt="Eduvery Logo" />
          </Link>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="d-flex flex-column gap-4">
            <NavLink to={"/edutest"} className={"nav-link"} onClick={handleNavLinkClick}>
              Edutest
            </NavLink>
            <NavLink to={"/about"} className={"nav-link"} onClick={handleNavLinkClick}>
              Hakkımızda
            </NavLink>
            <NavLink to={"/contact"} className={"nav-link"} onClick={handleNavLinkClick}>
              Bize Ulaşın
            </NavLink>
            {!userLogin ? <>
              <Link to={"/register"} className="btn btn-lg btn-outline-white">
              Kayıt Ol
            </Link>
            <Link to={"/login"} className="btn btn-lg btn-white">
              Giriş Yap
            </Link>
            </> : <Link to={"/profile"} className="btn btn-lg btn-white">
              Hesabım
            </Link>}
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </header>
  );
};

export default Header;
