/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useApi } from "../hooks/useApi";

const JobReportText = ({ initialContent }) => {
  const [content, setContent] = useState(initialContent);
  const [data, setData] = useState({});
  const { userData } = useSelector((state) => state.user);
  useEffect(() => {
    useApi()
      .get(`account/accountInfo?target_student=${userData.id}`)
      .then((res) => {
        setData(res.data?.student);
      });
  }, []);
  useEffect(() => {
    if (data.name) {
      setContent((prevContent) => prevContent.replace(/Aykut/g, data.quiz_inout_name.charAt(0).toUpperCase() + data.quiz_inout_name.slice(1)));
    }
  }, [data]);

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default JobReportText;
