import React, { useEffect } from "react";
import { QuestionsContextProvider } from "../context/QuestionContext";
import {useNavigate, useSearchParams } from "react-router-dom";
import QuestionDemoProvider from "../components/Providers/QuizDemoProvider";

const QuizDemo = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const demoID = searchParams.get("demoid")
    useEffect(() => {
        if(demoID === null || demoID === ""){
            navigate("/")
        }
    },[demoID, navigate])
  return (
    <QuestionsContextProvider>
      <QuestionDemoProvider />
    </QuestionsContextProvider>
  );
};

export default QuizDemo;
