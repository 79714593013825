import React from "react";
import { QuestionsContextProvider } from "../context/QuestionContext";
import QuestionsTwoPage from "../components/Providers/Questions-two-page";

const QuestionsTwo = () => {
  return (
    <QuestionsContextProvider>
      <QuestionsTwoPage />
    </QuestionsContextProvider>
  );
};

export default QuestionsTwo;
