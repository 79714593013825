import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

const HavaleBildirimi = () => {
  return (
    <div className="fixed-page-wrapper">
      <Container className="py-5">
        <div className="d-flex justify-content-center">
          <div className="col-lg-8 d-flex align-items-center justify-content-center flex-column roundedthreehalf circle-popup bg-white text-dark p-4">
            <div className="status-circle circle-green my-4">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  fill="currentColor"
                  class="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg>
              </span>
            </div>
            <h2 className="status-title"> Havale İsteğiniz başarıyla alınmıştır.</h2>

            <p>Havale durumunuzu profilinizden kontrol edebilirsiniz.</p>
            <Link
              to={"/profile"}
              className="btn btn-lg btn-white mx-auto btn-darkblue mt-3 bg-dark text-white"
            >
              <div className="d-flex gap-2 align-items-center">
                <span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                  >
                    <circle cx="15.5" cy="15.1021" r="15.1021" fill="white" />
                    <path
                      d="M16.9929 19.9975C16.8457 19.9975 16.6986 19.9433 16.5824 19.8272C16.3578 19.6026 16.3578 19.2308 16.5824 19.0062L20.8729 14.7157L16.5824 10.4252C16.3578 10.2006 16.3578 9.82883 16.5824 9.60424C16.807 9.37964 17.1787 9.37964 17.4033 9.60424L22.1043 14.3052C22.3289 14.5298 22.3289 14.9016 22.1043 15.1262L17.4033 19.8272C17.2871 19.9433 17.14 19.9975 16.9929 19.9975Z"
                      fill="#3C3744"
                    />
                    <path
                      d="M21.5628 15.2965H8.5286C8.21107 15.2965 7.94775 15.0331 7.94775 14.7156C7.94775 14.3981 8.21107 14.1348 8.5286 14.1348H21.5628C21.8804 14.1348 22.1437 14.3981 22.1437 14.7156C22.1437 15.0331 21.8804 15.2965 21.5628 15.2965Z"
                      fill="#3C3744"
                    />
                  </svg>
                </span>
                <p className="mb-0">Profil</p>
              </div>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HavaleBildirimi;
