import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import "../assets/scss/profile.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useApi } from "../hooks/useApi";
import moment from "moment/dist/moment";
import "moment/dist/locale/tr";
import { useDispatch } from "react-redux";
import { handleLogout } from "../store/user";

moment.locale("tr");

const Profile = () => {
  const [test, setTest] = useState(false);
  const [data, setData] = useState({});
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApi()
      .get(`account/accountInfo?target_student=${userData.id}`)
      .then((res) => {
        if (res.data.student.did_student_has_a_complated_quiz) {
          setTest(true);
        }
        setData(res.data?.student);
      });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    
  }, []);
  return (
    <div className="fixed-page-wrapper">
      <div className="profile">
        <Container>
          <div className="row flex-lg-row flex-column ">
            <div className="col-12">
              <div className="profile-info d-flex flex-column">
                <div className="d-flex align-items-center gap-3 mx-auto">
                  <div className="profile-picture">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="150"
                        height="151"
                        viewBox="0 0 150 151"
                        fill="none"
                      >
                        <circle cx="75" cy="75.5" r="75" fill="#3B4EFF" />
                        <path
                          d="M75 0.5C33.6675 0.5 0 34.1675 0 75.5C0 116.833 33.6675 150.5 75 150.5C116.333 150.5 150 116.833 150 75.5C150 34.1675 116.333 0.5 75 0.5ZM45 38L60 53H75H90L105 38V83C105 99.5675 91.5675 113 75 113C87.8148 113 99.5502 117.594 108.662 125.217C99.0734 131.708 87.4935 135.5 75 135.5C62.5065 135.5 50.9266 131.708 41.3379 125.217C50.4498 117.594 62.1852 113 75 113C58.4325 113 45 99.5675 45 83V38ZM60 68C58.0109 68 56.1032 68.7902 54.6967 70.1967C53.2902 71.6032 52.5 73.5109 52.5 75.5C52.5 77.4891 53.2902 79.3968 54.6967 80.8033C56.1032 82.2098 58.0109 83 60 83C61.9891 83 63.8968 82.2098 65.3033 80.8033C66.7098 79.3968 67.5 77.4891 67.5 75.5C67.5 73.5109 66.7098 71.6032 65.3033 70.1967C63.8968 68.7902 61.9891 68 60 68ZM90 68C88.0109 68 86.1032 68.7902 84.6967 70.1967C83.2902 71.6032 82.5 73.5109 82.5 75.5C82.5 77.4891 83.2902 79.3968 84.6967 80.8033C86.1032 82.2098 88.0109 83 90 83C91.9891 83 93.8968 82.2098 95.3033 80.8033C96.7098 79.3968 97.5 77.4891 97.5 75.5C97.5 73.5109 96.7098 71.6032 95.3033 70.1967C93.8968 68.7902 91.9891 68 90 68ZM75 83C73.0109 83 71.1032 83.3951 69.6967 84.0983C68.2902 84.8016 67.5 85.7554 67.5 86.75C67.5 87.7446 68.2902 88.6984 69.6967 89.4017C71.1032 90.1049 73.0109 90.5 75 90.5C76.9891 90.5 78.8968 90.1049 80.3033 89.4017C81.7098 88.6984 82.5 87.7446 82.5 86.75C82.5 85.7554 81.7098 84.8016 80.3033 84.0983C78.8968 83.3951 76.9891 83 75 83Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="profile-data">
                    <h4 className="user-name">
                      {data.name} {data.surname}
                    </h4>
                    <div className="d-flex align-items-center gap-2">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle
                            cx="12"
                            cy="12.5"
                            r="10"
                            stroke="white"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M12 8.5V12.5L14.5 15"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <p className="mb-0">
                        {moment(data.date_added).format("Do MMMM")} tarihinde
                        katıldı
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mx-auto mt-3">
                  <div className="d-flex gap-3">
                    <Link
                      to={"/profile-update"}
                      className="btn btn-lg btn-white mx-auto btn-darkblue"
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="31"
                            viewBox="0 0 32 31"
                            fill="none"
                          >
                            <circle
                              cx="16.0005"
                              cy="15.1021"
                              r="15.1021"
                              fill="#3C3744"
                            />
                            <path
                              d="M17.4969 19.9973C17.3497 19.9973 17.2026 19.9431 17.0864 19.8269C16.8618 19.6023 16.8618 19.2306 17.0864 19.006L21.3769 14.7155L17.0864 10.4249C16.8618 10.2003 16.8618 9.82859 17.0864 9.60399C17.311 9.3794 17.6828 9.3794 17.9073 9.60399L22.6083 14.305C22.8329 14.5296 22.8329 14.9013 22.6083 15.1259L17.9073 19.8269C17.7912 19.9431 17.644 19.9973 17.4969 19.9973Z"
                              fill="white"
                            />
                            <path
                              d="M22.0643 15.2965H9.03007C8.71254 15.2965 8.44922 15.0331 8.44922 14.7156C8.44922 14.3981 8.71254 14.1348 9.03007 14.1348H22.0643C22.3818 14.1348 22.6451 14.3981 22.6451 14.7156C22.6451 15.0331 22.3818 15.2965 22.0643 15.2965Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <p className="mb-0">Profili Düzenle</p>
                      </div>
                    </Link>
                    <button
                      className="btn"
                      onClick={() => dispatch(handleLogout())}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="29"
                        viewBox="0 0 30 29"
                        fill="none"
                      >
                        <path
                          d="M14.5827 28.4375C13.8923 28.4375 13.3327 27.8918 13.3327 27.2187C13.3327 26.5457 13.8923 26 14.5827 26C21.2561 26 26.666 20.7254 26.666 14.2187C26.666 7.71214 21.2561 2.4375 14.5827 2.4375C13.8923 2.4375 13.3327 1.89185 13.3327 1.21875C13.3327 0.545652 13.8923 -1.33527e-06 14.5827 -1.27492e-06C22.6368 -5.70799e-07 29.166 6.36595 29.166 14.2188C29.166 22.0715 22.6368 28.4375 14.5827 28.4375Z"
                          fill="white"
                          fillOpacity="0.65"
                        />
                        <path
                          d="M7.13323 18.232C7.62139 18.7079 7.62139 19.4796 7.13323 19.9555C6.64508 20.4315 5.85362 20.4315 5.36547 19.9555L0.365466 15.0805C-0.122688 14.6046 -0.122688 13.8329 0.365467 13.357L5.36547 8.48196C5.85362 8.00601 6.64508 8.00601 7.13324 8.48196C7.62139 8.95791 7.62139 9.72958 7.13324 10.2055L4.26712 13L17.916 13C18.6064 13 19.166 13.5457 19.166 14.2187C19.166 14.8918 18.6064 15.4375 17.916 15.4375L4.26712 15.4375L7.13323 18.232Z"
                          fill="white"
                          fillOpacity="0.65"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider"> </div>
          </div>
          <>
            {test ? (
              <Row>
                <div className="d-flex flex-column align-items-center gap-3 mb-4">
                  <img src="./images/edutest-white.svg" alt="edutest" />
                  <h4 className="text-center mb-4">Test Sonuçlarınız</h4> <br />
                </div>
                <div className="col-lg-12 mb-5">
                  <div className="job-info  mt-lg-0 mt-5 px-lg-0 px-2">
                    <div className="d-flex">
                      <div className="col-md-6">
                        <div className="job-review d-flex align-items-center gap-3">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="65"
                              height="69"
                              viewBox="0 0 65 69"
                              fill="none"
                            >
                              <mask id="path-1-inside-1_447_655" fill="white">
                                <path d="M0 10C0 4.47716 4.47715 0 10 0H55C60.5228 0 65 4.47715 65 10V58.1379C65 63.6608 60.5228 68.1379 55 68.1379H10C4.47715 68.1379 0 63.6608 0 58.1379V10Z" />
                              </mask>
                              <path
                                d="M0 10C0 4.47716 4.47715 0 10 0H55C60.5228 0 65 4.47715 65 10V58.1379C65 63.6608 60.5228 68.1379 55 68.1379H10C4.47715 68.1379 0 63.6608 0 58.1379V10Z"
                                fill="white"
                              />
                              <path
                                d="M-2 10C-2 3.37258 3.37258 -2 10 -2H55C61.6274 -2 67 3.37258 67 10H63C63 5.58172 59.4183 2 55 2H10C5.58172 2 2 5.58172 2 10H-2ZM67 61.1379C67 67.7654 61.6274 73.1379 55 73.1379H10C3.37258 73.1379 -2 67.7654 -2 61.1379L2 58.1379C2 60.8994 5.58172 63.1379 10 63.1379H55C59.4183 63.1379 63 60.8994 63 58.1379L67 61.1379ZM10 73.1379C3.37258 73.1379 -2 67.7654 -2 61.1379V10C-2 3.37258 3.37258 -2 10 -2V2C5.58172 2 2 5.58172 2 10V58.1379C2 60.8994 5.58172 63.1379 10 63.1379V73.1379ZM55 -2C61.6274 -2 67 3.37258 67 10V61.1379C67 67.7654 61.6274 73.1379 55 73.1379V63.1379C59.4183 63.1379 63 60.8994 63 58.1379V10C63 5.58172 59.4183 2 55 2V-2Z"
                                fill="#E5E5E5"
                                mask="url(#path-1-inside-1_447_655)"
                              />
                              <path
                                d="M49.7014 55.3525C49.0953 48.1539 46.6637 37.5499 37.647 37.7604C37.3656 37.5854 37.0917 37.4941 36.8483 37.4612C36.7951 37.0377 36.8838 36.6168 36.79 36.2035C39.6197 34.9915 41.4657 32.7272 42.1046 29.4816C43.2558 30.473 44.6504 29.119 45.3933 28.2036C46.6586 26.3805 46.651 22.2703 44.0799 21.6643C44.336 18.4973 44.6986 14.3795 41.8638 12.46C38.9503 5.20812 21.2391 7.11491 21.9288 15.8957C19.5275 17.5033 19.8952 21.076 21.5129 23.2262C17.887 25.0214 19.9789 31.5075 24.046 30.7494C24.6697 33.4245 26.3052 35.7116 28.7394 36.7081C28.7115 37.1366 28.6076 37.6158 28.567 38.0951C28.5137 38.1179 28.4554 38.1509 28.3996 38.1813C20.6508 38.3689 17.0249 45.2404 16.0842 52.2311C15.9548 53.9275 14.4664 58.8136 15.2043 59.8938C15.648 60.2057 16.1019 59.7645 16.1729 59.3156C16.2109 59.1939 16.2464 59.0697 16.2819 58.948C17.0629 58.3597 18.455 58.1924 20.2121 59.3714C20.3262 59.5692 20.4733 59.7594 20.666 59.9343C21.0945 60.0053 21.3887 59.8329 21.5839 59.4957C23.937 58.702 26.6196 58.7502 29.3302 59.9851C29.3505 60.2488 29.419 60.5023 29.5686 60.7381C29.9464 61.2909 30.2887 60.9283 30.5904 60.0282C31.9622 59.1128 33.2858 59.1711 34.5561 60.0915C34.6626 60.2488 34.8046 60.3857 34.9922 60.4947C35.3371 60.5454 35.5475 60.3806 35.6667 60.0865C38.4052 58.522 40.9712 58.6031 43.3091 59.7214C43.4358 59.7543 43.5753 59.7442 43.7249 59.6808C45.1119 58.7426 46.5065 58.6158 47.8782 59.123C48.0177 59.516 48.2662 59.8481 48.7125 60.0484C50.7663 60.2817 49.5974 56.567 49.7014 55.3525Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M40.6471 20.3887C40.6471 20.3887 40.6471 20.3786 40.6445 20.3735C40.6445 20.3786 40.6445 20.3837 40.6471 20.3887Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M42.3033 22.2602C42.2602 22.2931 42.2222 22.3286 42.1816 22.3667C42.0092 20.6957 42.3819 18.9182 41.781 17.3259C41.3246 16.5702 40.4067 16.1696 40.1252 15.2796C39.9883 14.2223 39.7855 11.8286 38.2413 13.2156C34.59 18.5227 26.6155 13.3728 23.5626 17.8254C22.2517 18.188 22.7385 19.7778 22.6979 21.3372C22.2567 20.0618 21.6203 18.3807 22.7867 17.3208C25.0434 17.3081 22.6092 14.0727 25.7026 11.7855C29.3489 9.33614 38.8879 7.92125 40.4371 13.3246C43.7461 15.0945 42.1943 19.2124 42.3033 22.2602Z"
                                fill="#4F5877"
                              />
                              <path
                                d="M42.2758 28.376C42.438 27.0753 42.3189 25.564 42.2656 24.2024C42.5293 23.9767 42.8184 23.7663 43.1379 23.6319C43.4751 23.4924 43.8681 23.6573 44.0076 23.997C45.1765 26.8395 43.1151 28.2645 42.2758 28.3786V28.376Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M40.6565 22.9904C40.1164 22.0649 39.2797 21.2662 38.235 21.0101C33.6278 19.8792 32.7429 24.4408 32.6998 25.0874C31.541 20.5892 26.0209 20.4371 24.5655 24.3647C24.5072 23.9337 24.1065 22.0117 24.4032 18.7914C27.4967 16.4409 33.7825 19.2352 38.1944 15.7183C38.4683 16.8973 39.4293 18.1525 40.5728 18.6342C40.9025 21.4361 40.6514 22.8915 40.6565 22.9904Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M23.7381 29.02C23.0028 28.8958 22.6072 28.7462 22.1914 28.3912C20.7157 27.4657 21.7908 22.9928 23.269 25.673C23.373 26.7912 23.5556 27.9094 23.7381 29.02Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M31.1752 26.0585C30.4931 30.4299 23.9918 28.1022 26.5806 24.2683C28.419 21.5425 31.5403 24.565 31.1752 26.0585Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M39.372 25.2698C38.8826 29.1518 33.1039 27.813 34.6228 24.1085C34.8281 23.6064 35.5153 23.0891 36.1289 22.8711C37.526 22.3208 39.4886 23.7002 39.372 25.2698Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M32.8122 26.3933C32.7894 30.4173 26.9676 31.9691 25 28.4294C25.71 31.211 26.6634 33.9393 29.4475 35.1513C34.2651 36.8984 40.8172 33.2598 40.6448 27.9071C38.6949 31.0183 33.5602 29.9051 32.8122 26.3933Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M37.3098 39.487C37.2312 40.9095 36.506 42.1164 35.8594 43.8939C35.8036 44.0435 35.7149 44.1804 35.6414 44.325C35.0024 43.5262 34.546 42.9431 34.1758 42.5272C35.0607 41.5383 36.0952 40.0474 36.7621 38.9038C36.9143 39.0889 37.0867 39.2816 37.3098 39.487Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M35.8029 38.5286C34.7557 39.5555 33.9265 40.7422 32.8362 41.7666C32.8312 41.7666 32.8235 41.7666 32.8159 41.7717C31.9614 40.7853 31.221 39.7558 30.293 38.8075C30.293 38.2801 30.293 37.6741 30.1738 37.1214C30.5542 37.1898 30.9421 37.2355 31.3504 37.2456C32.9376 37.2278 34.3677 37.0098 35.6432 36.6218C35.6355 37.2532 35.8283 37.8922 35.8029 38.5286Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M31.5541 42.5298C31.1256 42.8417 30.3015 43.4401 29.6042 43.9016C28.8334 42.8392 28.5317 41.0744 28.4023 39.8776C28.5266 39.7203 28.841 39.6671 29.0641 39.5327C29.9186 40.3771 30.8644 41.5257 31.5541 42.5298Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M33.4373 44.5202C33.2573 44.8194 33.171 45.1794 32.9935 45.4837C32.7603 45.4634 32.527 45.4457 32.2937 45.4228C32.2785 45.4203 32.2658 45.4203 32.2532 45.4178C31.9996 45.1769 31.7942 44.8574 31.5508 44.5912C31.9565 44.2717 32.3546 43.9446 32.7248 43.6885C32.9707 43.9547 33.204 44.2362 33.4373 44.5202Z"
                                fill="#F17092"
                              />
                              <path
                                d="M42.918 51.2854C41.384 50.7352 42.4261 57.1478 42.4312 58.091C42.4261 58.626 42.7456 59.5794 43.3085 59.7214C40.8059 59.9547 38.2145 60.0637 35.6662 60.0865C36.0567 59.1509 35.5521 56.9221 35.5952 56.2552C35.1692 53.0223 35.1717 49.8629 34.2031 46.7086C34.3806 46.4475 34.515 46.1533 34.6164 45.844C35.3847 46.8227 36.5739 46.1863 37.1926 45.03C38.7317 42.1496 38.7926 39.8041 38.7292 39.3984C46.3944 40.9071 47.3351 50.3219 47.6749 56.8131C47.7256 57.5256 47.6267 58.4232 47.8777 59.123C46.5414 59.3588 45.1494 59.5414 43.7244 59.6808C43.737 59.6758 43.7523 59.6707 43.7649 59.6631C44.8476 59.3461 43.808 51.6328 42.918 51.2854Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M30.5898 60.0283C31.599 57.0338 32.1695 48.0957 32.0884 47.3528C32.4966 47.3629 32.9175 47.3655 33.3207 47.2869C33.3968 50.7328 33.8304 54.204 34.0712 57.6474C34.2335 58.4132 34.1017 59.4325 34.5556 60.0917C33.2091 60.0917 31.8805 60.0689 30.5898 60.0283Z"
                                fill="#F17092"
                              />
                              <path
                                d="M29.4887 58.0784C29.4912 58.6921 29.2808 59.364 29.3315 59.9852C26.4358 59.8737 23.776 59.686 21.5852 59.4958C22.3788 58.1292 21.5522 54.0722 21.5776 52.9717C21.5471 52.67 21.5294 52.3074 21.2834 52.0969C20.0461 51.5442 20.2261 54.5108 20.0689 55.2259C20.1323 56.585 19.5389 58.1748 20.2134 59.3716C18.3954 59.1992 17.0236 59.0394 16.2832 58.9482C18.4004 51.605 17.8578 42.1345 26.9607 39.8752C26.91 41.2724 27.3639 43.1665 27.9597 44.4647C28.8548 46.4095 29.3467 46.0165 30.3914 45.4105C30.4801 45.9176 30.5055 46.4729 30.8503 46.8127C30.1404 50.8469 29.7144 54.1229 29.4887 58.0784Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M33.7075 31.1803C33.1826 31.7001 32.3763 31.5176 31.9731 30.9445C31.8336 30.7467 31.7829 30.478 31.5217 30.3841C31.111 30.1889 30.5912 30.5059 30.5836 30.9597C30.4035 32.1844 31.5243 33.3483 32.7186 33.4396C34.1613 33.5207 35.9971 31.8928 35.2263 30.4095C34.6228 29.3826 34.1208 30.762 33.7075 31.1829V31.1803Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M29.4066 24.6411C28.1794 24.2861 27.5734 25.9166 28.4811 26.4566C29.11 27.0348 30.1445 26.6975 30.0558 25.8024C29.9949 25.199 29.8656 24.844 29.4066 24.6411Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M36.579 24.1517C35.372 24.4103 35.2757 26.0889 36.65 26.2081C38.113 26.3171 37.8772 24.0553 36.579 24.1517Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M40.6711 19.6306C40.6458 19.3213 40.6128 18.9891 40.5722 18.6367C39.4287 18.1549 38.4677 16.8998 38.1938 15.7207C33.7819 19.2376 27.4961 16.4434 24.4026 18.7939C24.3595 19.2579 24.3316 19.694 24.3164 20.1048C25.465 19.618 28.206 18.6899 31.548 19.1692C35.9828 19.8081 37.9453 17.5007 37.9453 17.5007C37.9453 17.5007 38.3029 19.4709 40.6711 19.6332V19.6306Z"
                                fill="#F9B9B1"
                              />
                              <path
                                d="M35.7674 37.8668C35.7192 37.451 35.6381 37.0351 35.6432 36.6218C34.3677 37.0098 32.9376 37.2278 31.3504 37.2456C30.9421 37.2355 30.5542 37.1898 30.1738 37.1214C30.255 37.489 30.2778 37.8795 30.2879 38.2548C31.4416 38.7289 33.427 39.1296 35.7674 37.8643V37.8668Z"
                                fill="#F9B9B1"
                              />
                            </svg>
                          </span>
                          <div className="">
                            <p className="mb-0">Tavsiye Edilen Meslek 1</p>
                            <h5 className="job-name">{data.meslek1}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex gap-2">
                        <Link
                        to={`/job-report/${data.meslek1_id}`}
                            className="button-shadow shadow-green d-flex align-items-center"
                          >
                            <div className="d-flex align-items-center gap-2">
                              Raporu Görüntüle
                            </div>
                          </Link>
                          <a
                            href={data.meslek1_rapor}
                            download={true}
                            className="button-shadow shadow-blue ms-auto"
                          >
                            <div className="d-flex align-items-center gap-2">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="31"
                                  height="39"
                                  viewBox="0 0 31 39"
                                  fill="none"
                                >
                                  <path
                                    d="M30.4264 5.9065V37.2677C30.4264 38.0109 29.82 38.6172 29.0765 38.6172H4.19169C3.44827 38.6172 2.8418 38.0109 2.8418 37.2677V1.3495C2.8418 0.606297 3.44827 0 4.19169 0H24.5084L30.4264 5.9065Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M30.427 5.9065V37.2677C30.427 38.0109 29.8205 38.6172 29.0771 38.6172H27.1501C27.8935 38.6172 28.5 38.0109 28.5 37.2677V5.9065L22.5918 0H24.5188L30.427 5.9065Z"
                                    fill="#E5E5E5"
                                  />
                                  <path
                                    d="M30.4258 5.9065H25.8577C25.1143 5.9065 24.5078 5.30021 24.5078 4.557V0L30.4258 5.9065Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M24.7234 18.2866H8.70083C8.16284 18.2866 7.72266 17.8466 7.72266 17.3087C7.72266 16.7709 8.16284 16.3308 8.70083 16.3308H24.7234C25.2614 16.3308 25.7016 16.7709 25.7016 17.3087C25.7016 17.8563 25.2712 18.2866 24.7234 18.2866Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M24.7234 23.3035H8.70083C8.16284 23.3035 7.72266 22.8634 7.72266 22.3256C7.72266 21.7877 8.16284 21.3477 8.70083 21.3477H24.7234C25.2614 21.3477 25.7016 21.7877 25.7016 22.3256C25.7016 22.8732 25.2712 23.3035 24.7234 23.3035Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M24.7234 28.3198H8.70083C8.16284 28.3198 7.72266 27.8798 7.72266 27.3419C7.72266 26.8041 8.16284 26.364 8.70083 26.364H24.7234C25.2614 26.364 25.7016 26.8041 25.7016 27.3419C25.7016 27.8895 25.2712 28.3198 24.7234 28.3198Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M14.12 13.2016H0.572266V7.71563C0.572266 7.30491 0.904841 6.96265 1.32546 6.96265H14.12C14.5309 6.96265 14.8732 7.29513 14.8732 7.71563V12.4487C14.8732 12.8594 14.5407 13.2016 14.12 13.2016Z"
                                    fill="#F55B4B"
                                  />
                                  <path
                                    d="M14.8732 12.3509V12.4487C14.8732 12.8594 14.5407 13.2016 14.12 13.2016H0.572266V7.71563C0.572266 7.30491 0.904841 6.96265 1.32546 6.96265H1.70695V8.91844C1.70695 10.8156 3.24269 12.3606 5.15014 12.3606H14.8732V12.3509Z"
                                    fill="#DD4E43"
                                  />
                                  <path
                                    d="M0.572266 13.2014L2.84163 15.7831V13.2014H0.572266Z"
                                    fill="#DB1B1B"
                                  />
                                  <path
                                    d="M4.23971 11.2656V11.9208H2.19531V11.2656H2.69418V8.69377H2.19531V8.03857H4.23971C4.71902 8.03857 5.08094 8.14614 5.34505 8.37106C5.60916 8.59598 5.73632 8.89912 5.73632 9.27073C5.73632 9.48586 5.68741 9.68144 5.59937 9.85746C5.51134 10.0335 5.39396 10.1704 5.24723 10.2584C5.1005 10.3562 4.94399 10.4149 4.75814 10.454C4.57229 10.4931 4.34731 10.5029 4.07342 10.5029H3.70171V11.2559H4.23971V11.2656ZM3.70171 9.85746H3.84844C4.1908 9.85746 4.41578 9.79879 4.5136 9.69122C4.61141 9.58365 4.66032 9.43697 4.66032 9.27073C4.66032 9.13382 4.62119 9.00669 4.55272 8.9089C4.48425 8.81111 4.406 8.75244 4.31796 8.7231C4.22992 8.70354 4.0832 8.68399 3.87778 8.68399H3.69192V9.85746H3.70171Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M5.9707 11.9208V11.2656H6.55761V8.69377H5.9707V8.03857H7.80968C8.20095 8.03857 8.50419 8.05813 8.74873 8.10703C8.98349 8.15592 9.20848 8.26349 9.42368 8.41995C9.63888 8.5862 9.81495 8.80134 9.94211 9.07515C10.0693 9.34896 10.1377 9.65211 10.1377 9.98459C10.1377 10.2584 10.0888 10.5127 10.0008 10.7571C9.91276 11.0016 9.79538 11.1972 9.65844 11.3536C9.52149 11.5003 9.36498 11.6275 9.16935 11.7155C8.98349 11.8035 8.80742 11.8622 8.65091 11.8915C8.49441 11.9208 8.24986 11.9306 7.91728 11.9306H5.9707V11.9208ZM7.57492 11.2656H7.81946C8.11292 11.2656 8.34768 11.2265 8.52375 11.1483C8.69982 11.0701 8.83677 10.9332 8.93459 10.7474C9.04219 10.5518 9.09109 10.3073 9.09109 9.99437C9.09109 9.701 9.04219 9.44675 8.93459 9.24139C8.82699 9.03603 8.69004 8.88934 8.50419 8.81111C8.32812 8.73288 8.09335 8.69377 7.81946 8.69377H7.57492V11.2656Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M10.5879 11.9208V11.2656H11.1455V8.69377H10.5879V8.03857H14.0702V9.24139H13.3268V8.70355H12.1628V9.60321H13.004V10.2584H12.1628V11.2656H12.7594V11.9208H10.5879Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M12.6333 15.0203H2.85156V13.2014H14.1691V13.4948C14.1593 14.3358 13.4746 15.0203 12.6333 15.0203Z"
                                    fill="#E5E5E5"
                                  />
                                </svg>
                              </span>
                              Raporu İndir
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="col-lg-12 mb-5">
                  <div className="job-info  mt-lg-0 mt-5 px-lg-0 px-2">
                    <div className="d-flex">
                      <div className="col-md-6">
                        <div className="job-review d-flex align-items-center gap-3">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="65"
                              height="69"
                              viewBox="0 0 65 69"
                              fill="none"
                            >
                              <mask id="path-1-inside-1_447_655" fill="white">
                                <path d="M0 10C0 4.47716 4.47715 0 10 0H55C60.5228 0 65 4.47715 65 10V58.1379C65 63.6608 60.5228 68.1379 55 68.1379H10C4.47715 68.1379 0 63.6608 0 58.1379V10Z" />
                              </mask>
                              <path
                                d="M0 10C0 4.47716 4.47715 0 10 0H55C60.5228 0 65 4.47715 65 10V58.1379C65 63.6608 60.5228 68.1379 55 68.1379H10C4.47715 68.1379 0 63.6608 0 58.1379V10Z"
                                fill="white"
                              />
                              <path
                                d="M-2 10C-2 3.37258 3.37258 -2 10 -2H55C61.6274 -2 67 3.37258 67 10H63C63 5.58172 59.4183 2 55 2H10C5.58172 2 2 5.58172 2 10H-2ZM67 61.1379C67 67.7654 61.6274 73.1379 55 73.1379H10C3.37258 73.1379 -2 67.7654 -2 61.1379L2 58.1379C2 60.8994 5.58172 63.1379 10 63.1379H55C59.4183 63.1379 63 60.8994 63 58.1379L67 61.1379ZM10 73.1379C3.37258 73.1379 -2 67.7654 -2 61.1379V10C-2 3.37258 3.37258 -2 10 -2V2C5.58172 2 2 5.58172 2 10V58.1379C2 60.8994 5.58172 63.1379 10 63.1379V73.1379ZM55 -2C61.6274 -2 67 3.37258 67 10V61.1379C67 67.7654 61.6274 73.1379 55 73.1379V63.1379C59.4183 63.1379 63 60.8994 63 58.1379V10C63 5.58172 59.4183 2 55 2V-2Z"
                                fill="#E5E5E5"
                                mask="url(#path-1-inside-1_447_655)"
                              />
                              <path
                                d="M49.7014 55.3525C49.0953 48.1539 46.6637 37.5499 37.647 37.7604C37.3656 37.5854 37.0917 37.4941 36.8483 37.4612C36.7951 37.0377 36.8838 36.6168 36.79 36.2035C39.6197 34.9915 41.4657 32.7272 42.1046 29.4816C43.2558 30.473 44.6504 29.119 45.3933 28.2036C46.6586 26.3805 46.651 22.2703 44.0799 21.6643C44.336 18.4973 44.6986 14.3795 41.8638 12.46C38.9503 5.20812 21.2391 7.11491 21.9288 15.8957C19.5275 17.5033 19.8952 21.076 21.5129 23.2262C17.887 25.0214 19.9789 31.5075 24.046 30.7494C24.6697 33.4245 26.3052 35.7116 28.7394 36.7081C28.7115 37.1366 28.6076 37.6158 28.567 38.0951C28.5137 38.1179 28.4554 38.1509 28.3996 38.1813C20.6508 38.3689 17.0249 45.2404 16.0842 52.2311C15.9548 53.9275 14.4664 58.8136 15.2043 59.8938C15.648 60.2057 16.1019 59.7645 16.1729 59.3156C16.2109 59.1939 16.2464 59.0697 16.2819 58.948C17.0629 58.3597 18.455 58.1924 20.2121 59.3714C20.3262 59.5692 20.4733 59.7594 20.666 59.9343C21.0945 60.0053 21.3887 59.8329 21.5839 59.4957C23.937 58.702 26.6196 58.7502 29.3302 59.9851C29.3505 60.2488 29.419 60.5023 29.5686 60.7381C29.9464 61.2909 30.2887 60.9283 30.5904 60.0282C31.9622 59.1128 33.2858 59.1711 34.5561 60.0915C34.6626 60.2488 34.8046 60.3857 34.9922 60.4947C35.3371 60.5454 35.5475 60.3806 35.6667 60.0865C38.4052 58.522 40.9712 58.6031 43.3091 59.7214C43.4358 59.7543 43.5753 59.7442 43.7249 59.6808C45.1119 58.7426 46.5065 58.6158 47.8782 59.123C48.0177 59.516 48.2662 59.8481 48.7125 60.0484C50.7663 60.2817 49.5974 56.567 49.7014 55.3525Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M40.6471 20.3887C40.6471 20.3887 40.6471 20.3786 40.6445 20.3735C40.6445 20.3786 40.6445 20.3837 40.6471 20.3887Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M42.3033 22.2602C42.2602 22.2931 42.2222 22.3286 42.1816 22.3667C42.0092 20.6957 42.3819 18.9182 41.781 17.3259C41.3246 16.5702 40.4067 16.1696 40.1252 15.2796C39.9883 14.2223 39.7855 11.8286 38.2413 13.2156C34.59 18.5227 26.6155 13.3728 23.5626 17.8254C22.2517 18.188 22.7385 19.7778 22.6979 21.3372C22.2567 20.0618 21.6203 18.3807 22.7867 17.3208C25.0434 17.3081 22.6092 14.0727 25.7026 11.7855C29.3489 9.33614 38.8879 7.92125 40.4371 13.3246C43.7461 15.0945 42.1943 19.2124 42.3033 22.2602Z"
                                fill="#4F5877"
                              />
                              <path
                                d="M42.2758 28.376C42.438 27.0753 42.3189 25.564 42.2656 24.2024C42.5293 23.9767 42.8184 23.7663 43.1379 23.6319C43.4751 23.4924 43.8681 23.6573 44.0076 23.997C45.1765 26.8395 43.1151 28.2645 42.2758 28.3786V28.376Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M40.6565 22.9904C40.1164 22.0649 39.2797 21.2662 38.235 21.0101C33.6278 19.8792 32.7429 24.4408 32.6998 25.0874C31.541 20.5892 26.0209 20.4371 24.5655 24.3647C24.5072 23.9337 24.1065 22.0117 24.4032 18.7914C27.4967 16.4409 33.7825 19.2352 38.1944 15.7183C38.4683 16.8973 39.4293 18.1525 40.5728 18.6342C40.9025 21.4361 40.6514 22.8915 40.6565 22.9904Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M23.7381 29.02C23.0028 28.8958 22.6072 28.7462 22.1914 28.3912C20.7157 27.4657 21.7908 22.9928 23.269 25.673C23.373 26.7912 23.5556 27.9094 23.7381 29.02Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M31.1752 26.0585C30.4931 30.4299 23.9918 28.1022 26.5806 24.2683C28.419 21.5425 31.5403 24.565 31.1752 26.0585Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M39.372 25.2698C38.8826 29.1518 33.1039 27.813 34.6228 24.1085C34.8281 23.6064 35.5153 23.0891 36.1289 22.8711C37.526 22.3208 39.4886 23.7002 39.372 25.2698Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M32.8122 26.3933C32.7894 30.4173 26.9676 31.9691 25 28.4294C25.71 31.211 26.6634 33.9393 29.4475 35.1513C34.2651 36.8984 40.8172 33.2598 40.6448 27.9071C38.6949 31.0183 33.5602 29.9051 32.8122 26.3933Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M37.3098 39.487C37.2312 40.9095 36.506 42.1164 35.8594 43.8939C35.8036 44.0435 35.7149 44.1804 35.6414 44.325C35.0024 43.5262 34.546 42.9431 34.1758 42.5272C35.0607 41.5383 36.0952 40.0474 36.7621 38.9038C36.9143 39.0889 37.0867 39.2816 37.3098 39.487Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M35.8029 38.5286C34.7557 39.5555 33.9265 40.7422 32.8362 41.7666C32.8312 41.7666 32.8235 41.7666 32.8159 41.7717C31.9614 40.7853 31.221 39.7558 30.293 38.8075C30.293 38.2801 30.293 37.6741 30.1738 37.1214C30.5542 37.1898 30.9421 37.2355 31.3504 37.2456C32.9376 37.2278 34.3677 37.0098 35.6432 36.6218C35.6355 37.2532 35.8283 37.8922 35.8029 38.5286Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M31.5541 42.5298C31.1256 42.8417 30.3015 43.4401 29.6042 43.9016C28.8334 42.8392 28.5317 41.0744 28.4023 39.8776C28.5266 39.7203 28.841 39.6671 29.0641 39.5327C29.9186 40.3771 30.8644 41.5257 31.5541 42.5298Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M33.4373 44.5202C33.2573 44.8194 33.171 45.1794 32.9935 45.4837C32.7603 45.4634 32.527 45.4457 32.2937 45.4228C32.2785 45.4203 32.2658 45.4203 32.2532 45.4178C31.9996 45.1769 31.7942 44.8574 31.5508 44.5912C31.9565 44.2717 32.3546 43.9446 32.7248 43.6885C32.9707 43.9547 33.204 44.2362 33.4373 44.5202Z"
                                fill="#F17092"
                              />
                              <path
                                d="M42.918 51.2854C41.384 50.7352 42.4261 57.1478 42.4312 58.091C42.4261 58.626 42.7456 59.5794 43.3085 59.7214C40.8059 59.9547 38.2145 60.0637 35.6662 60.0865C36.0567 59.1509 35.5521 56.9221 35.5952 56.2552C35.1692 53.0223 35.1717 49.8629 34.2031 46.7086C34.3806 46.4475 34.515 46.1533 34.6164 45.844C35.3847 46.8227 36.5739 46.1863 37.1926 45.03C38.7317 42.1496 38.7926 39.8041 38.7292 39.3984C46.3944 40.9071 47.3351 50.3219 47.6749 56.8131C47.7256 57.5256 47.6267 58.4232 47.8777 59.123C46.5414 59.3588 45.1494 59.5414 43.7244 59.6808C43.737 59.6758 43.7523 59.6707 43.7649 59.6631C44.8476 59.3461 43.808 51.6328 42.918 51.2854Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M30.5898 60.0283C31.599 57.0338 32.1695 48.0957 32.0884 47.3528C32.4966 47.3629 32.9175 47.3655 33.3207 47.2869C33.3968 50.7328 33.8304 54.204 34.0712 57.6474C34.2335 58.4132 34.1017 59.4325 34.5556 60.0917C33.2091 60.0917 31.8805 60.0689 30.5898 60.0283Z"
                                fill="#F17092"
                              />
                              <path
                                d="M29.4887 58.0784C29.4912 58.6921 29.2808 59.364 29.3315 59.9852C26.4358 59.8737 23.776 59.686 21.5852 59.4958C22.3788 58.1292 21.5522 54.0722 21.5776 52.9717C21.5471 52.67 21.5294 52.3074 21.2834 52.0969C20.0461 51.5442 20.2261 54.5108 20.0689 55.2259C20.1323 56.585 19.5389 58.1748 20.2134 59.3716C18.3954 59.1992 17.0236 59.0394 16.2832 58.9482C18.4004 51.605 17.8578 42.1345 26.9607 39.8752C26.91 41.2724 27.3639 43.1665 27.9597 44.4647C28.8548 46.4095 29.3467 46.0165 30.3914 45.4105C30.4801 45.9176 30.5055 46.4729 30.8503 46.8127C30.1404 50.8469 29.7144 54.1229 29.4887 58.0784Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M33.7075 31.1803C33.1826 31.7001 32.3763 31.5176 31.9731 30.9445C31.8336 30.7467 31.7829 30.478 31.5217 30.3841C31.111 30.1889 30.5912 30.5059 30.5836 30.9597C30.4035 32.1844 31.5243 33.3483 32.7186 33.4396C34.1613 33.5207 35.9971 31.8928 35.2263 30.4095C34.6228 29.3826 34.1208 30.762 33.7075 31.1829V31.1803Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M29.4066 24.6411C28.1794 24.2861 27.5734 25.9166 28.4811 26.4566C29.11 27.0348 30.1445 26.6975 30.0558 25.8024C29.9949 25.199 29.8656 24.844 29.4066 24.6411Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M36.579 24.1517C35.372 24.4103 35.2757 26.0889 36.65 26.2081C38.113 26.3171 37.8772 24.0553 36.579 24.1517Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M40.6711 19.6306C40.6458 19.3213 40.6128 18.9891 40.5722 18.6367C39.4287 18.1549 38.4677 16.8998 38.1938 15.7207C33.7819 19.2376 27.4961 16.4434 24.4026 18.7939C24.3595 19.2579 24.3316 19.694 24.3164 20.1048C25.465 19.618 28.206 18.6899 31.548 19.1692C35.9828 19.8081 37.9453 17.5007 37.9453 17.5007C37.9453 17.5007 38.3029 19.4709 40.6711 19.6332V19.6306Z"
                                fill="#F9B9B1"
                              />
                              <path
                                d="M35.7674 37.8668C35.7192 37.451 35.6381 37.0351 35.6432 36.6218C34.3677 37.0098 32.9376 37.2278 31.3504 37.2456C30.9421 37.2355 30.5542 37.1898 30.1738 37.1214C30.255 37.489 30.2778 37.8795 30.2879 38.2548C31.4416 38.7289 33.427 39.1296 35.7674 37.8643V37.8668Z"
                                fill="#F9B9B1"
                              />
                            </svg>
                          </span>
                          <div className="">
                            <p className="mb-0">Tavsiye Edilen Meslek 2</p>
                            <h5 className="job-name">{data.meslek2}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex gap-2">
                        <Link
                        to={`/job-report/${data.meslek2_id}`}
                            className="button-shadow shadow-green d-flex align-items-center"
                          >
                            <div className="d-flex align-items-center gap-2">
                              Raporu Görüntüle
                            </div>
                          </Link>
                          <a
                            href={data.meslek2_rapor}
                            download={true}
                            className="button-shadow shadow-blue ms-auto"
                          >
                            <div className="d-flex align-items-center gap-2">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="31"
                                  height="39"
                                  viewBox="0 0 31 39"
                                  fill="none"
                                >
                                  <path
                                    d="M30.4264 5.9065V37.2677C30.4264 38.0109 29.82 38.6172 29.0765 38.6172H4.19169C3.44827 38.6172 2.8418 38.0109 2.8418 37.2677V1.3495C2.8418 0.606297 3.44827 0 4.19169 0H24.5084L30.4264 5.9065Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M30.427 5.9065V37.2677C30.427 38.0109 29.8205 38.6172 29.0771 38.6172H27.1501C27.8935 38.6172 28.5 38.0109 28.5 37.2677V5.9065L22.5918 0H24.5188L30.427 5.9065Z"
                                    fill="#E5E5E5"
                                  />
                                  <path
                                    d="M30.4258 5.9065H25.8577C25.1143 5.9065 24.5078 5.30021 24.5078 4.557V0L30.4258 5.9065Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M24.7234 18.2866H8.70083C8.16284 18.2866 7.72266 17.8466 7.72266 17.3087C7.72266 16.7709 8.16284 16.3308 8.70083 16.3308H24.7234C25.2614 16.3308 25.7016 16.7709 25.7016 17.3087C25.7016 17.8563 25.2712 18.2866 24.7234 18.2866Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M24.7234 23.3035H8.70083C8.16284 23.3035 7.72266 22.8634 7.72266 22.3256C7.72266 21.7877 8.16284 21.3477 8.70083 21.3477H24.7234C25.2614 21.3477 25.7016 21.7877 25.7016 22.3256C25.7016 22.8732 25.2712 23.3035 24.7234 23.3035Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M24.7234 28.3198H8.70083C8.16284 28.3198 7.72266 27.8798 7.72266 27.3419C7.72266 26.8041 8.16284 26.364 8.70083 26.364H24.7234C25.2614 26.364 25.7016 26.8041 25.7016 27.3419C25.7016 27.8895 25.2712 28.3198 24.7234 28.3198Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M14.12 13.2016H0.572266V7.71563C0.572266 7.30491 0.904841 6.96265 1.32546 6.96265H14.12C14.5309 6.96265 14.8732 7.29513 14.8732 7.71563V12.4487C14.8732 12.8594 14.5407 13.2016 14.12 13.2016Z"
                                    fill="#F55B4B"
                                  />
                                  <path
                                    d="M14.8732 12.3509V12.4487C14.8732 12.8594 14.5407 13.2016 14.12 13.2016H0.572266V7.71563C0.572266 7.30491 0.904841 6.96265 1.32546 6.96265H1.70695V8.91844C1.70695 10.8156 3.24269 12.3606 5.15014 12.3606H14.8732V12.3509Z"
                                    fill="#DD4E43"
                                  />
                                  <path
                                    d="M0.572266 13.2014L2.84163 15.7831V13.2014H0.572266Z"
                                    fill="#DB1B1B"
                                  />
                                  <path
                                    d="M4.23971 11.2656V11.9208H2.19531V11.2656H2.69418V8.69377H2.19531V8.03857H4.23971C4.71902 8.03857 5.08094 8.14614 5.34505 8.37106C5.60916 8.59598 5.73632 8.89912 5.73632 9.27073C5.73632 9.48586 5.68741 9.68144 5.59937 9.85746C5.51134 10.0335 5.39396 10.1704 5.24723 10.2584C5.1005 10.3562 4.94399 10.4149 4.75814 10.454C4.57229 10.4931 4.34731 10.5029 4.07342 10.5029H3.70171V11.2559H4.23971V11.2656ZM3.70171 9.85746H3.84844C4.1908 9.85746 4.41578 9.79879 4.5136 9.69122C4.61141 9.58365 4.66032 9.43697 4.66032 9.27073C4.66032 9.13382 4.62119 9.00669 4.55272 8.9089C4.48425 8.81111 4.406 8.75244 4.31796 8.7231C4.22992 8.70354 4.0832 8.68399 3.87778 8.68399H3.69192V9.85746H3.70171Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M5.9707 11.9208V11.2656H6.55761V8.69377H5.9707V8.03857H7.80968C8.20095 8.03857 8.50419 8.05813 8.74873 8.10703C8.98349 8.15592 9.20848 8.26349 9.42368 8.41995C9.63888 8.5862 9.81495 8.80134 9.94211 9.07515C10.0693 9.34896 10.1377 9.65211 10.1377 9.98459C10.1377 10.2584 10.0888 10.5127 10.0008 10.7571C9.91276 11.0016 9.79538 11.1972 9.65844 11.3536C9.52149 11.5003 9.36498 11.6275 9.16935 11.7155C8.98349 11.8035 8.80742 11.8622 8.65091 11.8915C8.49441 11.9208 8.24986 11.9306 7.91728 11.9306H5.9707V11.9208ZM7.57492 11.2656H7.81946C8.11292 11.2656 8.34768 11.2265 8.52375 11.1483C8.69982 11.0701 8.83677 10.9332 8.93459 10.7474C9.04219 10.5518 9.09109 10.3073 9.09109 9.99437C9.09109 9.701 9.04219 9.44675 8.93459 9.24139C8.82699 9.03603 8.69004 8.88934 8.50419 8.81111C8.32812 8.73288 8.09335 8.69377 7.81946 8.69377H7.57492V11.2656Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M10.5879 11.9208V11.2656H11.1455V8.69377H10.5879V8.03857H14.0702V9.24139H13.3268V8.70355H12.1628V9.60321H13.004V10.2584H12.1628V11.2656H12.7594V11.9208H10.5879Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M12.6333 15.0203H2.85156V13.2014H14.1691V13.4948C14.1593 14.3358 13.4746 15.0203 12.6333 15.0203Z"
                                    fill="#E5E5E5"
                                  />
                                </svg>
                              </span>
                              Raporu İndir
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="col-lg-12 mb-5">
                  <div className="job-info  mt-lg-0 mt-5 px-lg-0 px-2">
                    <div className="d-flex">
                      <div className="col-md-6">
                        <div className="job-review d-flex align-items-center gap-3">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="65"
                              height="69"
                              viewBox="0 0 65 69"
                              fill="none"
                            >
                              <mask id="path-1-inside-1_447_655" fill="white">
                                <path d="M0 10C0 4.47716 4.47715 0 10 0H55C60.5228 0 65 4.47715 65 10V58.1379C65 63.6608 60.5228 68.1379 55 68.1379H10C4.47715 68.1379 0 63.6608 0 58.1379V10Z" />
                              </mask>
                              <path
                                d="M0 10C0 4.47716 4.47715 0 10 0H55C60.5228 0 65 4.47715 65 10V58.1379C65 63.6608 60.5228 68.1379 55 68.1379H10C4.47715 68.1379 0 63.6608 0 58.1379V10Z"
                                fill="white"
                              />
                              <path
                                d="M-2 10C-2 3.37258 3.37258 -2 10 -2H55C61.6274 -2 67 3.37258 67 10H63C63 5.58172 59.4183 2 55 2H10C5.58172 2 2 5.58172 2 10H-2ZM67 61.1379C67 67.7654 61.6274 73.1379 55 73.1379H10C3.37258 73.1379 -2 67.7654 -2 61.1379L2 58.1379C2 60.8994 5.58172 63.1379 10 63.1379H55C59.4183 63.1379 63 60.8994 63 58.1379L67 61.1379ZM10 73.1379C3.37258 73.1379 -2 67.7654 -2 61.1379V10C-2 3.37258 3.37258 -2 10 -2V2C5.58172 2 2 5.58172 2 10V58.1379C2 60.8994 5.58172 63.1379 10 63.1379V73.1379ZM55 -2C61.6274 -2 67 3.37258 67 10V61.1379C67 67.7654 61.6274 73.1379 55 73.1379V63.1379C59.4183 63.1379 63 60.8994 63 58.1379V10C63 5.58172 59.4183 2 55 2V-2Z"
                                fill="#E5E5E5"
                                mask="url(#path-1-inside-1_447_655)"
                              />
                              <path
                                d="M49.7014 55.3525C49.0953 48.1539 46.6637 37.5499 37.647 37.7604C37.3656 37.5854 37.0917 37.4941 36.8483 37.4612C36.7951 37.0377 36.8838 36.6168 36.79 36.2035C39.6197 34.9915 41.4657 32.7272 42.1046 29.4816C43.2558 30.473 44.6504 29.119 45.3933 28.2036C46.6586 26.3805 46.651 22.2703 44.0799 21.6643C44.336 18.4973 44.6986 14.3795 41.8638 12.46C38.9503 5.20812 21.2391 7.11491 21.9288 15.8957C19.5275 17.5033 19.8952 21.076 21.5129 23.2262C17.887 25.0214 19.9789 31.5075 24.046 30.7494C24.6697 33.4245 26.3052 35.7116 28.7394 36.7081C28.7115 37.1366 28.6076 37.6158 28.567 38.0951C28.5137 38.1179 28.4554 38.1509 28.3996 38.1813C20.6508 38.3689 17.0249 45.2404 16.0842 52.2311C15.9548 53.9275 14.4664 58.8136 15.2043 59.8938C15.648 60.2057 16.1019 59.7645 16.1729 59.3156C16.2109 59.1939 16.2464 59.0697 16.2819 58.948C17.0629 58.3597 18.455 58.1924 20.2121 59.3714C20.3262 59.5692 20.4733 59.7594 20.666 59.9343C21.0945 60.0053 21.3887 59.8329 21.5839 59.4957C23.937 58.702 26.6196 58.7502 29.3302 59.9851C29.3505 60.2488 29.419 60.5023 29.5686 60.7381C29.9464 61.2909 30.2887 60.9283 30.5904 60.0282C31.9622 59.1128 33.2858 59.1711 34.5561 60.0915C34.6626 60.2488 34.8046 60.3857 34.9922 60.4947C35.3371 60.5454 35.5475 60.3806 35.6667 60.0865C38.4052 58.522 40.9712 58.6031 43.3091 59.7214C43.4358 59.7543 43.5753 59.7442 43.7249 59.6808C45.1119 58.7426 46.5065 58.6158 47.8782 59.123C48.0177 59.516 48.2662 59.8481 48.7125 60.0484C50.7663 60.2817 49.5974 56.567 49.7014 55.3525Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M40.6471 20.3887C40.6471 20.3887 40.6471 20.3786 40.6445 20.3735C40.6445 20.3786 40.6445 20.3837 40.6471 20.3887Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M42.3033 22.2602C42.2602 22.2931 42.2222 22.3286 42.1816 22.3667C42.0092 20.6957 42.3819 18.9182 41.781 17.3259C41.3246 16.5702 40.4067 16.1696 40.1252 15.2796C39.9883 14.2223 39.7855 11.8286 38.2413 13.2156C34.59 18.5227 26.6155 13.3728 23.5626 17.8254C22.2517 18.188 22.7385 19.7778 22.6979 21.3372C22.2567 20.0618 21.6203 18.3807 22.7867 17.3208C25.0434 17.3081 22.6092 14.0727 25.7026 11.7855C29.3489 9.33614 38.8879 7.92125 40.4371 13.3246C43.7461 15.0945 42.1943 19.2124 42.3033 22.2602Z"
                                fill="#4F5877"
                              />
                              <path
                                d="M42.2758 28.376C42.438 27.0753 42.3189 25.564 42.2656 24.2024C42.5293 23.9767 42.8184 23.7663 43.1379 23.6319C43.4751 23.4924 43.8681 23.6573 44.0076 23.997C45.1765 26.8395 43.1151 28.2645 42.2758 28.3786V28.376Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M40.6565 22.9904C40.1164 22.0649 39.2797 21.2662 38.235 21.0101C33.6278 19.8792 32.7429 24.4408 32.6998 25.0874C31.541 20.5892 26.0209 20.4371 24.5655 24.3647C24.5072 23.9337 24.1065 22.0117 24.4032 18.7914C27.4967 16.4409 33.7825 19.2352 38.1944 15.7183C38.4683 16.8973 39.4293 18.1525 40.5728 18.6342C40.9025 21.4361 40.6514 22.8915 40.6565 22.9904Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M23.7381 29.02C23.0028 28.8958 22.6072 28.7462 22.1914 28.3912C20.7157 27.4657 21.7908 22.9928 23.269 25.673C23.373 26.7912 23.5556 27.9094 23.7381 29.02Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M31.1752 26.0585C30.4931 30.4299 23.9918 28.1022 26.5806 24.2683C28.419 21.5425 31.5403 24.565 31.1752 26.0585Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M39.372 25.2698C38.8826 29.1518 33.1039 27.813 34.6228 24.1085C34.8281 23.6064 35.5153 23.0891 36.1289 22.8711C37.526 22.3208 39.4886 23.7002 39.372 25.2698Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M32.8122 26.3933C32.7894 30.4173 26.9676 31.9691 25 28.4294C25.71 31.211 26.6634 33.9393 29.4475 35.1513C34.2651 36.8984 40.8172 33.2598 40.6448 27.9071C38.6949 31.0183 33.5602 29.9051 32.8122 26.3933Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M37.3098 39.487C37.2312 40.9095 36.506 42.1164 35.8594 43.8939C35.8036 44.0435 35.7149 44.1804 35.6414 44.325C35.0024 43.5262 34.546 42.9431 34.1758 42.5272C35.0607 41.5383 36.0952 40.0474 36.7621 38.9038C36.9143 39.0889 37.0867 39.2816 37.3098 39.487Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M35.8029 38.5286C34.7557 39.5555 33.9265 40.7422 32.8362 41.7666C32.8312 41.7666 32.8235 41.7666 32.8159 41.7717C31.9614 40.7853 31.221 39.7558 30.293 38.8075C30.293 38.2801 30.293 37.6741 30.1738 37.1214C30.5542 37.1898 30.9421 37.2355 31.3504 37.2456C32.9376 37.2278 34.3677 37.0098 35.6432 36.6218C35.6355 37.2532 35.8283 37.8922 35.8029 38.5286Z"
                                fill="#FCD5CC"
                              />
                              <path
                                d="M31.5541 42.5298C31.1256 42.8417 30.3015 43.4401 29.6042 43.9016C28.8334 42.8392 28.5317 41.0744 28.4023 39.8776C28.5266 39.7203 28.841 39.6671 29.0641 39.5327C29.9186 40.3771 30.8644 41.5257 31.5541 42.5298Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M33.4373 44.5202C33.2573 44.8194 33.171 45.1794 32.9935 45.4837C32.7603 45.4634 32.527 45.4457 32.2937 45.4228C32.2785 45.4203 32.2658 45.4203 32.2532 45.4178C31.9996 45.1769 31.7942 44.8574 31.5508 44.5912C31.9565 44.2717 32.3546 43.9446 32.7248 43.6885C32.9707 43.9547 33.204 44.2362 33.4373 44.5202Z"
                                fill="#F17092"
                              />
                              <path
                                d="M42.918 51.2854C41.384 50.7352 42.4261 57.1478 42.4312 58.091C42.4261 58.626 42.7456 59.5794 43.3085 59.7214C40.8059 59.9547 38.2145 60.0637 35.6662 60.0865C36.0567 59.1509 35.5521 56.9221 35.5952 56.2552C35.1692 53.0223 35.1717 49.8629 34.2031 46.7086C34.3806 46.4475 34.515 46.1533 34.6164 45.844C35.3847 46.8227 36.5739 46.1863 37.1926 45.03C38.7317 42.1496 38.7926 39.8041 38.7292 39.3984C46.3944 40.9071 47.3351 50.3219 47.6749 56.8131C47.7256 57.5256 47.6267 58.4232 47.8777 59.123C46.5414 59.3588 45.1494 59.5414 43.7244 59.6808C43.737 59.6758 43.7523 59.6707 43.7649 59.6631C44.8476 59.3461 43.808 51.6328 42.918 51.2854Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M30.5898 60.0283C31.599 57.0338 32.1695 48.0957 32.0884 47.3528C32.4966 47.3629 32.9175 47.3655 33.3207 47.2869C33.3968 50.7328 33.8304 54.204 34.0712 57.6474C34.2335 58.4132 34.1017 59.4325 34.5556 60.0917C33.2091 60.0917 31.8805 60.0689 30.5898 60.0283Z"
                                fill="#F17092"
                              />
                              <path
                                d="M29.4887 58.0784C29.4912 58.6921 29.2808 59.364 29.3315 59.9852C26.4358 59.8737 23.776 59.686 21.5852 59.4958C22.3788 58.1292 21.5522 54.0722 21.5776 52.9717C21.5471 52.67 21.5294 52.3074 21.2834 52.0969C20.0461 51.5442 20.2261 54.5108 20.0689 55.2259C20.1323 56.585 19.5389 58.1748 20.2134 59.3716C18.3954 59.1992 17.0236 59.0394 16.2832 58.9482C18.4004 51.605 17.8578 42.1345 26.9607 39.8752C26.91 41.2724 27.3639 43.1665 27.9597 44.4647C28.8548 46.4095 29.3467 46.0165 30.3914 45.4105C30.4801 45.9176 30.5055 46.4729 30.8503 46.8127C30.1404 50.8469 29.7144 54.1229 29.4887 58.0784Z"
                                fill="#5B6583"
                              />
                              <path
                                d="M33.7075 31.1803C33.1826 31.7001 32.3763 31.5176 31.9731 30.9445C31.8336 30.7467 31.7829 30.478 31.5217 30.3841C31.111 30.1889 30.5912 30.5059 30.5836 30.9597C30.4035 32.1844 31.5243 33.3483 32.7186 33.4396C34.1613 33.5207 35.9971 31.8928 35.2263 30.4095C34.6228 29.3826 34.1208 30.762 33.7075 31.1829V31.1803Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M29.4066 24.6411C28.1794 24.2861 27.5734 25.9166 28.4811 26.4566C29.11 27.0348 30.1445 26.6975 30.0558 25.8024C29.9949 25.199 29.8656 24.844 29.4066 24.6411Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M36.579 24.1517C35.372 24.4103 35.2757 26.0889 36.65 26.2081C38.113 26.3171 37.8772 24.0553 36.579 24.1517Z"
                                fill="#3B4968"
                              />
                              <path
                                d="M40.6711 19.6306C40.6458 19.3213 40.6128 18.9891 40.5722 18.6367C39.4287 18.1549 38.4677 16.8998 38.1938 15.7207C33.7819 19.2376 27.4961 16.4434 24.4026 18.7939C24.3595 19.2579 24.3316 19.694 24.3164 20.1048C25.465 19.618 28.206 18.6899 31.548 19.1692C35.9828 19.8081 37.9453 17.5007 37.9453 17.5007C37.9453 17.5007 38.3029 19.4709 40.6711 19.6332V19.6306Z"
                                fill="#F9B9B1"
                              />
                              <path
                                d="M35.7674 37.8668C35.7192 37.451 35.6381 37.0351 35.6432 36.6218C34.3677 37.0098 32.9376 37.2278 31.3504 37.2456C30.9421 37.2355 30.5542 37.1898 30.1738 37.1214C30.255 37.489 30.2778 37.8795 30.2879 38.2548C31.4416 38.7289 33.427 39.1296 35.7674 37.8643V37.8668Z"
                                fill="#F9B9B1"
                              />
                            </svg>
                          </span>
                          <div className="">
                            <p className="mb-0">Tavsiye Edilen Meslek 3</p>
                            <h5 className="job-name">{data.meslek3}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex gap-2">
                        <Link
                        to={`/job-report/${data.meslek3_id}`}
                            className="button-shadow shadow-green d-flex align-items-center"
                          >
                            <div className="d-flex align-items-center gap-2">
                              Raporu Görüntüle
                            </div>
                          </Link>
                          <a
                            href={data.meslek3_rapor}
                            download={true}
                            className="button-shadow shadow-blue ms-auto"
                          >
                            <div className="d-flex align-items-center gap-2">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="31"
                                  height="39"
                                  viewBox="0 0 31 39"
                                  fill="none"
                                >
                                  <path
                                    d="M30.4264 5.9065V37.2677C30.4264 38.0109 29.82 38.6172 29.0765 38.6172H4.19169C3.44827 38.6172 2.8418 38.0109 2.8418 37.2677V1.3495C2.8418 0.606297 3.44827 0 4.19169 0H24.5084L30.4264 5.9065Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M30.427 5.9065V37.2677C30.427 38.0109 29.8205 38.6172 29.0771 38.6172H27.1501C27.8935 38.6172 28.5 38.0109 28.5 37.2677V5.9065L22.5918 0H24.5188L30.427 5.9065Z"
                                    fill="#E5E5E5"
                                  />
                                  <path
                                    d="M30.4258 5.9065H25.8577C25.1143 5.9065 24.5078 5.30021 24.5078 4.557V0L30.4258 5.9065Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M24.7234 18.2866H8.70083C8.16284 18.2866 7.72266 17.8466 7.72266 17.3087C7.72266 16.7709 8.16284 16.3308 8.70083 16.3308H24.7234C25.2614 16.3308 25.7016 16.7709 25.7016 17.3087C25.7016 17.8563 25.2712 18.2866 24.7234 18.2866Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M24.7234 23.3035H8.70083C8.16284 23.3035 7.72266 22.8634 7.72266 22.3256C7.72266 21.7877 8.16284 21.3477 8.70083 21.3477H24.7234C25.2614 21.3477 25.7016 21.7877 25.7016 22.3256C25.7016 22.8732 25.2712 23.3035 24.7234 23.3035Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M24.7234 28.3198H8.70083C8.16284 28.3198 7.72266 27.8798 7.72266 27.3419C7.72266 26.8041 8.16284 26.364 8.70083 26.364H24.7234C25.2614 26.364 25.7016 26.8041 25.7016 27.3419C25.7016 27.8895 25.2712 28.3198 24.7234 28.3198Z"
                                    fill="#CECECE"
                                  />
                                  <path
                                    d="M14.12 13.2016H0.572266V7.71563C0.572266 7.30491 0.904841 6.96265 1.32546 6.96265H14.12C14.5309 6.96265 14.8732 7.29513 14.8732 7.71563V12.4487C14.8732 12.8594 14.5407 13.2016 14.12 13.2016Z"
                                    fill="#F55B4B"
                                  />
                                  <path
                                    d="M14.8732 12.3509V12.4487C14.8732 12.8594 14.5407 13.2016 14.12 13.2016H0.572266V7.71563C0.572266 7.30491 0.904841 6.96265 1.32546 6.96265H1.70695V8.91844C1.70695 10.8156 3.24269 12.3606 5.15014 12.3606H14.8732V12.3509Z"
                                    fill="#DD4E43"
                                  />
                                  <path
                                    d="M0.572266 13.2014L2.84163 15.7831V13.2014H0.572266Z"
                                    fill="#DB1B1B"
                                  />
                                  <path
                                    d="M4.23971 11.2656V11.9208H2.19531V11.2656H2.69418V8.69377H2.19531V8.03857H4.23971C4.71902 8.03857 5.08094 8.14614 5.34505 8.37106C5.60916 8.59598 5.73632 8.89912 5.73632 9.27073C5.73632 9.48586 5.68741 9.68144 5.59937 9.85746C5.51134 10.0335 5.39396 10.1704 5.24723 10.2584C5.1005 10.3562 4.94399 10.4149 4.75814 10.454C4.57229 10.4931 4.34731 10.5029 4.07342 10.5029H3.70171V11.2559H4.23971V11.2656ZM3.70171 9.85746H3.84844C4.1908 9.85746 4.41578 9.79879 4.5136 9.69122C4.61141 9.58365 4.66032 9.43697 4.66032 9.27073C4.66032 9.13382 4.62119 9.00669 4.55272 8.9089C4.48425 8.81111 4.406 8.75244 4.31796 8.7231C4.22992 8.70354 4.0832 8.68399 3.87778 8.68399H3.69192V9.85746H3.70171Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M5.9707 11.9208V11.2656H6.55761V8.69377H5.9707V8.03857H7.80968C8.20095 8.03857 8.50419 8.05813 8.74873 8.10703C8.98349 8.15592 9.20848 8.26349 9.42368 8.41995C9.63888 8.5862 9.81495 8.80134 9.94211 9.07515C10.0693 9.34896 10.1377 9.65211 10.1377 9.98459C10.1377 10.2584 10.0888 10.5127 10.0008 10.7571C9.91276 11.0016 9.79538 11.1972 9.65844 11.3536C9.52149 11.5003 9.36498 11.6275 9.16935 11.7155C8.98349 11.8035 8.80742 11.8622 8.65091 11.8915C8.49441 11.9208 8.24986 11.9306 7.91728 11.9306H5.9707V11.9208ZM7.57492 11.2656H7.81946C8.11292 11.2656 8.34768 11.2265 8.52375 11.1483C8.69982 11.0701 8.83677 10.9332 8.93459 10.7474C9.04219 10.5518 9.09109 10.3073 9.09109 9.99437C9.09109 9.701 9.04219 9.44675 8.93459 9.24139C8.82699 9.03603 8.69004 8.88934 8.50419 8.81111C8.32812 8.73288 8.09335 8.69377 7.81946 8.69377H7.57492V11.2656Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M10.5879 11.9208V11.2656H11.1455V8.69377H10.5879V8.03857H14.0702V9.24139H13.3268V8.70355H12.1628V9.60321H13.004V10.2584H12.1628V11.2656H12.7594V11.9208H10.5879Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M12.6333 15.0203H2.85156V13.2014H14.1691V13.4948C14.1593 14.3358 13.4746 15.0203 12.6333 15.0203Z"
                                    fill="#E5E5E5"
                                  />
                                </svg>
                              </span>
                              Raporu İndir
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="mt-5">
                  <div className="d-flex align-items-center flex-column">
                    <p className="text-center mb-5">
                      Üniversite giriş sınavına hazırlanan bireylerin
                      tercihlerini, <br />
                      kişilik tiplerine, ilgi alanlarına ve mesleki
                      yatkınlıklarına göre <br /> belirlemeyi amaçlayan bir
                      testtir.
                    </p>
                    <div className="mt-4 d-flex justify-content-center">
                      <Link
                        to={
                          data.quiz_right > 0
                            ? "/questions"
                            : "/before-questions"
                        }
                        className="button-shadow shadow-blue"
                      >
                        Testi Tekrar Çöz
                      </Link>
                    </div>
                  </div>
                </section>
              </Row>
            ) : (
              <section className="mt-5">
                <div className="d-flex align-items-center flex-column">
                  <div className="d-flex align-items-center gap-3 mb-4">
                    <img src="./images/edutest-white.svg" alt="edutest" />
                  </div>
                  <p className="text-center mb-5">
                    Üniversite giriş sınavına hazırlanan bireylerin
                    tercihlerini, <br />
                    kişilik tiplerine, ilgi alanlarına ve mesleki
                    yatkınlıklarına göre <br /> belirlemeyi amaçlayan bir
                    testtir.
                  </p>
                  <div className="mt-4 d-flex justify-content-center">
                    <Link
                      to={
                        data.quiz_right > 0 ? "/questions" : "/before-questions"
                      }
                      className="button-shadow shadow-blue"
                    >
                      Hemen Teste Başla
                    </Link>
                  </div>
                </div>
              </section>
            )}
          </>
        </Container>
      </div>
    </div>
  );
};

export default Profile;
