import React, { useEffect, useState } from "react";
import QuestionsHeader from "../Layout/QuestionsHeader";
import { useQuestionContext } from "../../context/QuestionContext";

const Arrow = ({ data }) => {
  const { activeStep, nextQuestionRequest } = useQuestionContext();
  const [arrows, setArrows] = useState([]);
  const [firstSelect, setFirstSelect] = useState(false);
  const [lastSelect, setLastSelect] = useState(false);
  const [finish, setFinish] = useState(false);
  const [colors, setColors] = useState([
    "88, 204, 2",
    "151, 2, 204",
    "67, 118, 108",
    "204, 87, 2",
    "2, 180, 204",
  ]);
  const alphabet = "abcdefghijklmnopqrstuvwxyz";

  function compareOrder(a, b) {
    return a.order - b.order;
  }
  const handleClickKeys = (element, value) => {
    if (!firstSelect) {
      const findFirst = arrows.find(
        (arrow) =>
          arrow.firstElement === element.target.id ||
          arrow.lastElement === element.target.id
      );
      if (findFirst) {
        const newData = arrows.filter(
          (arrow) =>
            arrow.firstElement !== element.target.id &&
            arrow.lastElement !== element.target.id
        );
        setArrows(newData);
        document
          .querySelector(`#${findFirst.firstElement}`)
          .classList.remove("active");
        document
          .querySelector(`#${findFirst.lastElement}`)
          .classList.remove("active");
        const color = window
          .getComputedStyle(element.target)
          .backgroundColor.match(/\d+(?=(,|$))/g)
          .join(", ");
        setColors((colors) => [...colors, color]);

        return false;
      }
      setFirstSelect({ element, id: element.target.id, index: value });
      element.target.classList.add("active");
      element.target.style.setProperty("--bg-color", colors[0]);
    } else {
      const findLast = arrows.find(
        (arrow) =>
          arrow.firstElement === element.target.id ||
          arrow.lastElement === element.target.id
      );
      if (findLast) {
        const newData = arrows.filter(
          (arrow) =>
            arrow.firstElement !== element.target.id &&
            arrow.lastElement !== element.target.id
        );
        setArrows(newData);
        document
          .querySelector(`#${findLast.firstElement}`)
          .classList.remove("active");
        document
          .querySelector(`#${findLast.lastElement}`)
          .classList.remove("active");
        const color = window
          .getComputedStyle(element.target)
          .backgroundColor.match(/\d+(?=(,|$))/g)
          .join(", ");
        setColors((colors) => [...colors, color]);

        return false;
      }
      if (firstSelect.index.type === value.type) return false;
      setLastSelect({ element, id: element.target.id, index: value });
      element.target.classList.add("active");
      element.target.style.setProperty("--bg-color", colors[0]);
      setColors((colors) => colors.slice(1));
    }
  };
  useEffect(() => {
    if (firstSelect && lastSelect) {
      firstSelect.element.target.classList.add("selected");
      lastSelect.element.target.classList.add("selected");

      setArrows([
        ...arrows,
        {
          firstElement: firstSelect.id,
          lastElement: lastSelect.id,
          firstElementValue: firstSelect.index,
          lastElementValue: lastSelect.index,
        },
      ]);
      setFirstSelect(false);
      setLastSelect(false);
    }
    if (arrows.length === 5) {
      setFinish(true);
    } else {
      if (finish) {
        setFinish(false);
      }
    }
  }, [arrows, firstSelect, lastSelect]);
  const questionFinish = () => {
    let totalPoint = 0;
    arrows.forEach((element) => {
      if (element.firstElementValue.value === element.lastElementValue.id) {
        totalPoint = totalPoint + 2;
      }
    });
    const datas = {
      question: activeStep + 1,
      answer: 1,
      point: totalPoint,
    };
    nextQuestionRequest(datas);
  };
  return (
    <div className="before-questions">
      <QuestionsHeader />
      <div className="container ">
        <div className="question-area">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <p className="question-q">
              <span>{activeStep + 1}. </span>
              Okulunuzun bilim fuarı için bir proje hazırlıyorsunuz. Projeniz,
              bir model roket yapımını içeriyor. Roketinizi tasarlarken ve inşa
              ederken çeşitli risklerle karşılaşabilirsiniz. Bu riskleri nasıl
              yöneteceğinizi ve olası sonuçları nasıl ele alacağınızı
              düşündükten sonra eşleştirin.
            </p>
            <div className="row justify-content-between">
              <div className="col-4">
                <div className="row h-100 justify-content-between">
                  <div className="d-flex h-100 flex-column justify-content-between">
                    {data.values.keys.sort(compareOrder).map((d, key) => (
                      <div
                        className="answers-check mb-2"
                        id={`key-${d.order}`}
                        key={key}
                        onClick={(e) => handleClickKeys(e, d)}
                      >
                        <span>{alphabet[key]}.</span> {d.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-7">
                <div className="row h-100 gap15 justify-content-between">
                  {data.values.values.sort(compareOrder).map((val, key) => (
                    <div
                      className="answers-check "
                      id={`value-${val.order}`}
                      key={key}
                      onClick={(e) => handleClickKeys(e, val)}
                    >
                      <span>{key + 1}.</span> {val.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* {arrows.map((arrow, key) => (
              <Xarrow
                key={key}
                start={arrow.firstElement} 
                end={arrow.lastElement}
                color={colors[key]} 
              />
            ))} */}
            <div className="d-flex justify-content-center w-100 question-fixed-bottom">
              <button
                className={`button-shadow ${
                  !finish ? "shadow-gray" : "shadow-blue"
                }`}
                onClick={questionFinish}
                disabled={!finish}
              >
                İlerle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Arrow;
